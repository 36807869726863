// polkadotApi.js
import { ApiPromise, WsProvider } from '@polkadot/api';
import { web3Enable, web3Accounts, web3FromAddress } from "@polkadot/extension-dapp";
import { encodeAddress } from "@polkadot/util-crypto";

let currentAddress = "";
let api;

const POLKADOT_PREFIX = 0;

// Initialize Polkadot API
export const initPolkadotApi = async () => {
  const wsProvider = new WsProvider('wss://rpc.polkadot.io');
  api = await ApiPromise.create({ provider: wsProvider });
};

export const connectWallet = async (walletType) => {
    const extensionNameMap = {
        nova: "nova",
        talisman: "talisman",
        subwallet: "subwallet-js",
        polkadotjs: "polkadot-js",
        "polkadot-js": "polkadot-js",
    };

    const extensionName = extensionNameMap[walletType];

  
    // Enable wallet extensions
    const extensions = await web3Enable('OG Tracker');
    if (extensions.length === 0) {
      console.error('No wallet extension enabled');
      throw new Error('Please install a compatible wallet extension.');
    }
  
    // Get all accounts
    const accounts = await web3Accounts();
    if (!accounts || accounts.length === 0) {
      console.error('No accounts found');
      throw new Error('No accounts found in the selected wallet');
    }
  
    const polkadotAccounts = accounts.map((account) => ({
      address: encodeAddress(account.address, POLKADOT_PREFIX),
      meta: {
        name: account.meta.name || account.address.slice(0, 6),
        source: extensionName,
      },
    })).sort((a1, a2) => a1.address > a2.address);

    return polkadotAccounts
};

// Connect to Polkadot Wallet and fetch accounts
export const connectToPolkadotWallet = async (walletType) => {
    await web3Enable("Polkadot Wallet Login App");
    const accounts = await web3Accounts();
    if (accounts.length === 0) {
        throw new Error("No accounts found");
    }

    const filteredAccounts = accounts.filter((account) => account.meta.source === walletType);

    return filteredAccounts.map((account) => ({
        address: account.address,
        meta: {
            name: account.meta.name || account.address.slice(0, 6),
            source: account.meta.source,
        },
    }));
};

// Sign a message for authentication
export const signMessage = async (address, message) => {
    try {
        const injector = await web3FromAddress(address);
        const signature = await injector.signer.signRaw({
            address,
            data: message,
            type: "bytes",
        });
        return signature.signature;
    } catch (error) {
        return null;
    }
};

// Set Current Address
export const setCurrentAddress = (address) => {
    currentAddress = address;
    localStorage.setItem("currentAddress", address);
};

// Get Current Address
export const getConnectedAddress = () => {
    return currentAddress;
};

// Logout Wallet
export const logoutWallet = () => {
    currentAddress = "";
    localStorage.removeItem("currentAddress");
};
