// walletMapping.js
import novaLogo from './nova.png';
import talismanLogo from './talisman.svg';
import subwalletLogo from './subwallet.svg';

export const walletMapping = {
  nova: {
    extensionName: 'nova',
    metaSource: 'nova',
    displayName: 'Nova Wallet',
    logo: novaLogo,
  },
  talisman: {
    extensionName: 'talisman',
    metaSource: 'talisman',
    displayName: 'Talisman',
    logo: talismanLogo,
  },
  subwallet: {
    extensionName: 'subwallet-js',
    metaSource: 'subwallet-js',
    displayName: 'SubWallet',
    logo: subwalletLogo,
  },
  polkadotjs: {
    extensionName: 'polkadot-js',
    metaSource: 'polkadot-js',
    displayName: 'Polkadot.js',
    logo: null, // We'll use the SiPolkadot icon in the component
  },
};
