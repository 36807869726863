// Navbar.js
import React, { useState, useEffect } from 'react';
import { FaUser, FaMoon, FaSun } from 'react-icons/fa';
import AccountSelectionModal from './AccountSelectionModal'; // Import the modal component
import AccountSelectionModal2 from './AccountSelectionModal2'; // Import the modal component
import ogLogo from '../oglogo.png';

const Navbar = ({
  address,
  accountName,
  availableAccounts,
  onLogout,
  onThemeToggle,
  theme,
  proposalCounts,
  onSelectAccount,
  walletType
}) => {
  const [popupOpen, setPopupOpen] = useState(false); // Manage pop-up state

  // Close the pop-up when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.account-popup')) {
        setPopupOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Determine what to display in the user button
  const displayName =
    accountName === 'WalletConnect'
      ? `${address.slice(0, 6)}...${address.slice(-4)}`
      : accountName || `${address.slice(0, 6)}...${address.slice(-4)}`;

  return (
    <nav className="w-full bg-opacity-90 bg-kog-200 p-2 shadow-lg flex items-center justify-between z-50 fixed">
      {/* Logo */}
      <div className="text-white text-2xl font-bold flex items-center">
        <img src={ogLogo} alt="OG Logo" className="w-10 h-10 mr-2" />
        {/* Title (hidden on mobile) */}
        <span className="cursor-pointer hidden sm:inline-block">
          OG Tracker{' '}
          <span className="font-bold text-xl text-kog-900 tracking-tighter">
            &lt; report &gt;
          </span>
        </span>
      </div>

      {/* Profile Button - Centered */}
      <div className="flex-1 flex justify-center md:ml-[-210px] ml-[-40px]">
        <div
          className="relative border py-1 rounded-full border-kog-500 bg-kog-100 px-6 shadow-inner hover:shadow-md cursor-pointer"
          onClick={() => setPopupOpen(true)} // Open the modal
        >
          <button className="flex items-center text-white space-x-2">
            <FaUser className="text-white w-5 h-5" />
            <span>{displayName}</span> {/* Show account name or address */}
          </button>
        </div>
      </div>

      {/* Account Selection Modal */}
      {/*
      <AccountSelectionModal
        isOpen={popupOpen}
        availableAccounts={availableAccounts}
        address={address}
        proposalCounts={proposalCounts}
        onSelectAccount={onSelectAccount}
        onLogout={onLogout}
        onClose={() => setPopupOpen(false)} // Close modal
        accessToken={accessToken}
      />
      */ }
      
      <AccountSelectionModal2
          isOpen={popupOpen}     
          walletType={walletType}
          onSelectAccount={onSelectAccount}
          onClose={() => setPopupOpen (false)}
          loggedAccountAddress={address}   
          onSelectAccount={onSelectAccount}
          onLogout={onLogout}
        />
        
      {/* Theme Toggle */}
      {/* Uncomment below if you wish to add theme toggling */}
      {/* <button
        onClick={onThemeToggle}
        className="sm:mr-4 text-white px-4 py-2 rounded-full border border-kog-500 bg-gradient-to-t hover:from-kog-300 hover:to-kog-900"
      >
        {theme === 'light' ? (
          <FaMoon className="text-white w-4 h-4" />
        ) : (
          <FaSun className="text-white w-4 h-4" />
        )}
      </button> */}
    </nav>
  );
};

export default Navbar;
