// App.js
import React, { useState, useEffect, useMemo, useCallback } from "react";
import Cookies from "universal-cookie";
/*
import {
  initPolkadotApi,
  connectWallet,
  setCurrentAddress,
  logoutWallet,
} from './polkadotApi';
*/
import { initPolkadotApi, connectToPolkadotWallet, signMessage, setCurrentAddress, getConnectedAddress, logoutWallet } from "./polkadotApi";

import { signatureVerify, cryptoWaitReady } from "@polkadot/util-crypto";
import axios from "axios";
import ProposalList from "./components/ProposalList";
import Navbar from "./components/Navbar";
import WalletSelectionModal from "./components/WalletSelectionModal";
import AccountSelectionModal from "./components/AccountSelectionModal";
import AccountSelectionModal2 from "./components/AccountSelectionModal2";
import WalletConnectSignApproveModal from "./components/WalletConnectSignApproveModal";
import AccountNotFound from "./components/AccountNotFound";
import AdminDeliverables from "./components/AdminDeliverables";
import useSWR from "swr"; // Use SWR for data fetching optimization
import SignClient from "@walletconnect/sign-client";
import { WalletConnectModal } from "@walletconnect/modal";
import { v4 as uuidv4 } from "uuid"; // Generate unique IDs
import { walletMapping } from "./walletMapping";
import "./index.css";

const fetcher = (url) => fetch(url).then((res) => res.json());
const cookies = new Cookies(null, { path: "/" });

function App() {
    const [address, setAddress] = useState("");
    const [accountName, setAccountName] = useState("");
    const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
    const [isConnectSignApproveModal, setIsConnectSignApproveModal] = useState(false);
    const [connectSignApproveMessage, setConnectSignApproveMessage] = useState("");
    const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
    const [isAccountModal2Open, setIsAccountModal2Open] = useState(false);
    const [availableAccounts, setAvailableAccounts] = useState([]);
    const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
    const [theme, setTheme] = useState("dark"); // Default theme is dark
    const [signClient, setSignClient] = useState(null);
    const [walletConnectModal, setWalletConnectModal] = useState(null);
    const [wcSession, setWcSession] = useState(null);
    const [selectedWallet, setSelectedWallet] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [me, setMe] = useState({});
    const [signature, setSignature] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showAccountNotFound, setShowAccountNotFound] = useState(false);

    const WALLET_CONNECT_POLCADOT_CHAINS = ["polkadot:91b171bb158e2d3848fa23a9f1c25182"];

    let proposals;
    let proposalError;

    const _createJsonRpcRequestHandler = (rpcRequest) => async (client, chainId, topic, address, message) => {
        if (typeof client === "undefined") {
            throw new Error("WalletConnect is not initialized");
        }

        let signResult;
        try {
            setIsConnectSignApproveModal(true);
            signResult = await rpcRequest(client, chainId, topic, address, message);
        } catch (err) {
            console.error("RPC request failed: ", err);

            signResult = {
                address,
                valid: false,
                result: err?.message ?? err,
            };
        } finally {
            setIsConnectSignApproveModal(false);
        }

        return signResult;
    };

    const polkadotRpc = {
        signMessage: _createJsonRpcRequestHandler(async (client, chainId, topic, address, message) => {
            setConnectSignApproveMessage(message);
            const result = await client.request({
                chainId,
                topic,
                request: {
                    method: "polkadot_signMessage",
                    params: {
                        address,
                        message,
                    },
                },
            });

            return {
                method: "polkadot_signMessage",
                address,
                message,
                result: result.signature,
            };
        }),
    };

    const convertAccounts = (accounts) => {
        return accounts.map((account) => {
            if (typeof account === "string") {
                for (const chain of WALLET_CONNECT_POLCADOT_CHAINS) {
                    if (account.startsWith(chain)) {
                        return {
                            address: account.slice(chain.length + 1),
                            meta: {
                                name: "",
                                source: "walletconnect",
                            },
                        };
                    }
                }
            } else {
                return account;
            }
        });
    };

    const refresh_success = (response) => {
        
        setIsLoggedIn(true);
        setAccessToken(response.data.token);


        setMe(response.data.me);
        setAddress(response.data.me.account.address);
        setAccountName(response.data.me.account.meta.name);

        const savedWallet = localStorage.getItem("selectedWallet");
        setSelectedWallet(savedWallet);
    }

    useEffect(() => {
        async function initialize() {
 
            const token = cookies.get("token") || null;
            const logged = isLoggedIn || false;

            if (token && !logged) {
  
                try {  
                    const me = cookies.get("me");
     
                    const config = {
                        headers: {
                            Authorization: `${token}`,
                            Account: me.account.address,
                        },
                    };

                    const response = await axios.post(
                        `${process.env.REACT_APP_BACKEND_API_URL}/refresh`,
                        {
                            l: 2,
                            accounts: [me],
                        },
                        config
                    );
                    cookies.set("token", response.data.token, { path: "/" });

                    refresh_success(response);
                } catch (err) {
                    return;
                }
            }
                
            document.documentElement.classList.toggle("dark", theme === "dark");
        }

        initialize();

    }, [theme, cookies, address, accessToken, isLoggedIn, selectedWallet]);

    useEffect(() => {
        if (address) {
            document.documentElement.classList.add("wallet-connected"); // Apply to <html>
        } else {
            document.documentElement.classList.remove("wallet-connected");
        }
    }, [address]);

    useEffect(() => {
        const initialize = async () => {
            await initPolkadotApi();

            if (!walletConnectModal) {
                const projectId = process.env.REACT_APP_PROJECT_ID;
                if (!projectId) {
                    console.error("You need to provide REACT_APP_PROJECT_ID env variable");
                    return;
                }

                const _modal = new WalletConnectModal({
                    projectId,
                    themeMode: "dark",
                    walletConnectVersion: 2,
                    standaloneChains: WALLET_CONNECT_POLCADOT_CHAINS[0],
                });
                setWalletConnectModal(_modal);
            } 
        };
        initialize();
    }, [walletConnectModal]);

    const selectAccount = useCallback(
        async (account) => {
            try {
                const resp1 = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/init`);

                const message = "Please sign this message to log in to OG Tracker: " + resp1.data.nonce;
                const signed = await signMessage(account.address, message);
                if (!signed) {
                    throw new Error("Failed to sign message");
                }

                const config = {
                    headers: {
                        Account: account.address,
                    },
                };
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_API_URL}/login`,
                    {
                        l: 1,
                        accounts: [account],
                        message: message,
                        signature: signed,
                    },
                    config
                );

                cookies.set("token", response.data.token, { path: "/" });
                cookies.set("me", response.data.me, { path: "/" });

                setAccessToken(response.data.token);
                setMe(response.data.me);
                setAddress(response.data.me.account.address);
                setAccountName(response.data.me.account.meta.name);

                localStorage.setItem("accountName", response.data.me.account.meta.name);
                localStorage.setItem("currentAddress", response.data.me.account.address);

                setIsLoggedIn(true);
            } catch (err) {
                setIsLoggedIn(false);
                setShowAccountNotFound(true);
                console.error(err);
            }
            setIsAccountModal2Open(false);
        },
        [accessToken, address, me, accountName, isAccountModal2Open, AccountNotFound]
    );

    /*
    const selectAccount_old = useCallback( async (account) => {
        setAddress(account.address);
        setAccountName(account.meta.name || '');
        setCurrentAddress(account.address);
        localStorage.setItem('accountName', account.meta.name || '');
        localStorage.setItem('currentAddress', account.address);

        
        try{ 
        const config = {
            headers: {
                Account: account.address
            }
        };

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/login`, {
            p: 3, 
            accounts: availableAccounts.sort((a1, a2) => a1.address > a2.address),
            message: signature.message,
            signature: signature.signature
        }, config);
        
        cookies.set('token', response.data.token, { path: '/' });

        setAccessToken(response.data.token);
        setMe(response.data.me);
        setIsLoggedIn(true);
        } catch (err) {
        handleLogout();
        console.error('Failed to retrieve an access token');
        setIsLoggedIn(false);
        }

        setIsAccountModalOpen(false);
    }, [accessToken, availableAccounts, signature]);
    */

    const handleWalletSelection = useCallback(
        async (walletType) => {
            setSelectedWallet(walletType);
            localStorage.setItem("selectedWallet", walletType);

            setIsAccountModal2Open(true);
            setIsWalletModalOpen(false);
            /*
            try {
            setIsAccountModal2Open(true);
            setIsWalletModalOpen(false);
            return;

            const accounts = res.accounts;
            const message = res.message;
            const signature = res.signature;

            if (accounts.length > 0) {

                const config = {
                headers: {
                    Account: localStorage.getItem('currentAddress') || null
                    }
                };
        
                //try to get access token
                try{ 
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/login`, {
                    p: 4, 
                    accounts: accounts.sort((a1, a2) => a1.address > a2.address),
                    message: message,
                    signature: signature
                });

                setSignature({
                    message: message,
                    signature: signature
                });

                cookies.set('token', response.data.token, { path: '/' });

                setAccessToken(response.data.token);
                setMe(response.data.me);
                setIsLoggedIn(true);
                } catch (err) {
                handleLogout();
                console.error('Failed to retrieve an access token');
                setIsLoggedIn(false);
                }
                
                setIsAccountModalOpen(true);
            } else {
                console.error('No accounts found');
            }
            } catch (error) {
            console.error('Failed to fetch accounts:', error);
            } finally {
            setIsWalletModalOpen(false);
            }
            */
        },
        [selectedWallet, isAccountModal2Open]
    );

    const handleWalletConnect = useCallback(async () => {
        let _signClient = signClient;

        if (!_signClient) {
            const projectId = process.env.REACT_APP_PROJECT_ID;
            if (!projectId) {
                console.error("You need to provide REACT_APP_PROJECT_ID env variable");
                return;
            }

            try {
                _signClient = await SignClient.init({
                    projectId,
                    relayUrl: "wss://relay.walletconnect.com",
                    metadata: {
                        name: "OG Tracker Self Report",
                        description: "Your App Description",
                        url: window.location.origin,
                        icons: [],
                    },
                });

                _signClient.on("session_event", ({ event }) => {
                    // console.warn('Session event', event)
                });

                _signClient.on("session_update", ({ topic, params }) => {
                    const { namespaces } = params;
                    const _session = _signClient.session.get(topic);

                    const updatedSession = { ..._session, namespaces };

                    // onSessionUpdate(updatedSession)

                    console.warn("Session updated", updatedSession);
                });

                _signClient.on("session_delete", () => {
                    handleLogout();
                });

                setSignClient(_signClient);
            } catch (err) {}
        }

        if (!_signClient || !walletConnectModal) return;

        const connectParams = {
            requiredNamespaces: {
                polkadot: {
                    chains: WALLET_CONNECT_POLCADOT_CHAINS,
                    methods: [
                        //'polkadot_signTransaction',
                        "polkadot_signMessage",
                    ],
                    events: [],
                },
            },
        };

        try {
            const { uri, approval } = await _signClient.connect(connectParams);

            if (uri) {
                walletConnectModal.openModal({ uri });
            }

            const session = await approval();
            const accounts = session.namespaces.polkadot.accounts;
            const connectedAddress = accounts[0].split(":")[2];


            const chainId = WALLET_CONNECT_POLCADOT_CHAINS[0];
            const address = connectedAddress;

            const resp1 = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/init`);
            const message = "Please sign this message to log in to OG Tracker: " + resp1.data.nonce;

            polkadotRpc
                .signMessage(_signClient, chainId, session.topic, address, message)
                .then(async (result) => {
                    //console.log("Signed message result:", result);

                    //try to get access token
                    try {
                        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/login`, {
                            p: 5,
                            accounts: convertAccounts(accounts),
                            message: result.message,
                            signature: result.result,
                        });
                        
                        cookies.set("token", response.data.token, { path: "/" });
                        cookies.set("me", response.data.me, { path: "/" });

                        setAccessToken(response.data.token);
                        setMe(response.data.me);
                        setAddress(response.data.me.account.address);
                        setAccountName(response.data.me.account.meta.name);

                        localStorage.setItem("accountName", response.data.me.account.meta.name);
                        localStorage.setItem("currentAddress", response.data.me.account.address);

                        setIsLoggedIn(true);

                        setSelectedWallet("walletConnect");

                        localStorage.setItem("accountName", "WalletConnect");
                        localStorage.setItem("currentAddress", connectedAddress);
                        localStorage.setItem("selectedWallet", "walletConnect");

                        setIsLoggedIn(true);
                    } catch (err) {
                        console.error("Failed to retrieve an access token");
                        setIsLoggedIn(false);
                    }

                    setIsWalletModalOpen(false);
                    setIsAccountModalOpen(false);
                    setIsAccountModal2Open(false);
                    setWcSession(session);
                    walletConnectModal.closeModal(); // Close the modal after connecting
                })
                .catch((error) => {
                    console.error("Error signing message:", error);
                });
        } catch (err) {
            console.log(err);
        }
    }, [signClient, walletConnectModal, accessToken, me]);

    const handleLogout = useCallback(async () => {
        if (wcSession && signClient) {
            await signClient.disconnect({
                topic: wcSession.topic,
                reason: { code: 6000, message: "User disconnected" },
            });
            setWcSession(null);
        }
        await logoutWallet();
        setAddress("");
        setAccountName("");
        setSelectedWallet("");
        setAccessToken("");

        localStorage.removeItem("currentAddress");
        localStorage.removeItem("accountName");
        localStorage.removeItem("selectedWallet");


        cookies.remove("token");
        cookies.remove("me");

        setIsWalletModalOpen(true);
    }, [wcSession, signClient, address, accountName, accessToken]);

    const toggleTheme = useCallback(() => {
        const newTheme = theme === "light" ? "dark" : "light";
        setTheme(newTheme);
        localStorage.setItem("theme", newTheme);
        document.documentElement.classList.toggle("dark", newTheme === "dark");
    }, [theme]);

    // Memoize the proposals to avoid recalculating every render
    const memoizedProposals = useMemo(() => proposals || [], [proposals]);

    if (proposalError) {
        return <div>Error loading proposals.</div>;
    }
    

    return (
        <div className="App">
            {!address ? (
                <div className="flex flex-col items-center justify-center h-screen space-y-4">
                    <h1 className="text-2xl text-center font-bold text-white mb-10 md:text-6xl px-2" style={{ textShadow: "2px 2px 8px rgba(0, 0, 0, 0.5)" }}>
                        Connect your wallet to submit your{" "}
                        <span className="text-kog-900" style={{ textShadow: "2px 2px 8px rgba(0, 0, 0, 0.5)" }}>
                            Report
                        </span>
                    </h1>

                    <button
                        onClick={() => setIsWalletModalOpen(true)}
                        className="px-8 py-4 animate-glow font-bold text-2xl bg-kog-100 text-kog-900 hover:bg-kog-900 hover:text-white rounded-full border border-kog-500 shadow-[0px_1px_10px_3px_rgba(254,0,188,0.2)]"
                    >
                        Connect Wallet
                    </button>

                    <WalletSelectionModal
                        isOpen={isWalletModalOpen}
                        onClose={() => setIsWalletModalOpen(false)}
                        handleWalletSelection={handleWalletSelection}
                        onWalletConnect={handleWalletConnect}
                    />

                    <WalletConnectSignApproveModal
                        isOpen={isConnectSignApproveModal}
                        connectSignApproveMessage={connectSignApproveMessage}
                        onClose={() => setIsConnectSignApproveModal(false)}
                        handleWalletSelection={handleWalletSelection}
                        handleWalletConnect={handleWalletConnect}
                    />

                    <AccountNotFound
                        isOpen={showAccountNotFound}
                        onClose={() => setShowAccountNotFound(false)}
                    />
                    {/*
          <AccountSelectionModal
            isOpen={isAccountModalOpen}
            availableAccounts={availableAccounts}
            address={address}
            selectedWallet={selectedWallet} // Pass selectedWallet here
            onSelectAccount={selectAccount}
            isLoading={isLoadingAccounts}
            onClose={() => setIsAccountModalOpen(false)}
            onLogout={handleLogout}
            accessToken={accessToken}
          />
          */}

                    <AccountSelectionModal2
                        isOpen={isAccountModal2Open}
                        loggedAccountAddress={address}
                        walletType={selectedWallet}
                        onSelectAccount={selectAccount}
                        onClose={() => setIsAccountModal2Open(false)}
                        onLogout={handleLogout}
                    />
                </div>
            ) : (
                <>
                    <Navbar
                        address={address}
                        accountName={accountName}
                        availableAccounts={availableAccounts}
                        onLogout={handleLogout}
                        onThemeToggle={toggleTheme}
                        theme={theme}
                        onSelectAccount={selectAccount}
                        walletType={selectedWallet}
                    />
                    {me.admin ? <AdminDeliverables address={address} accessToken={accessToken} /> : <ProposalList address={address} proposals={memoizedProposals} accessToken={accessToken} />}
                </>
            )}
        </div>
    );
}

export default App;
