// src/components/ProposalList.js
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; // Generate unique IDs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ProposalStats from './ProposalStats'; // Include ProposalStats component
import 'react-tabs/style/react-tabs.css';
import FileUpload from './FileUpload';
import { IoMdCheckmark, IoMdAdd } from "react-icons/io";
import { AiOutlineHourglass, AiOutlineEdit, AiOutlineCheck, AiOutlineSave, AiOutlineClose, AiOutlineFlag } from 'react-icons/ai';
import introJs from 'intro.js';
import 'intro.js/introjs.css';

const ProposalList = ({ address, accessToken }) => {
  const [proposals, setProposals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editableDeliverables, setEditableDeliverables] = useState({});
  const [editableReports, setEditableReports] = useState({});
  const [submissionLog, setSubmissionLog] = useState({});
  const [selectedProposalId, setSelectedProposalId] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [reportTitle, setReportTitle] = useState('');
  const [reportText, setReportText] = useState('');
  const [urls, setUrls] = useState(['']);
  const [selectedTab, setSelectedTab] = useState(0);
  const [files, setFiles] = useState([]);
  const [notification, setNotification] = useState(null);
  const [editingTask, setEditingTask] = useState({});
  const [editTexts, setEditTexts] = useState({}); // Store edits for each task and proposal
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [selectedTaskKey, setSelectedTaskKey] = useState(null);
  const [deliverableText, setDeliverableText] = useState({});
  const modalRef = useRef(null);
  const [tourStep, setTourStep] = useState(0); // To track tour progress
  const [bounties, setBounties] = useState([]);
  const [selectedBountyId, setSelectedBountyId] = useState(null);

  const [bountyReportTitle, setBountyReportTitle] = useState('');
  const [bountyReportText, setBountyReportText] = useState('');
  const [bountyUrls, setBountyUrls] = useState(['']);
  const [bountyFiles, setBountyFiles] = useState([]);

  const selectedBounty = bounties && bounties.find(bounty => bounty.bNum === selectedBountyId);

  const handleBountySaveReport = async () => {
    if (
      !selectedBountyId ||
      (bountyReportTitle.trim() === '' && bountyReportText.trim() === '' && bountyUrls.every(url => url.trim() === '') && bountyFiles.length === 0)
    ) {
      triggerNotification('Bounty Report', true);
      return;
    }

    const report_uuid = uuidv4();
    try {
      const updatedBounties = bounties.map((bounty) => {
        if (bounty.bNum === selectedBountyId) {
          // If the reports array doesn't exist, create it
          if (!bounty.reports) {
            bounty.reports = {};
          }

          const newReport = {
            title: bountyReportTitle, // Title of the report
            text: bountyReportText, // Main text/comment of the report
            urls: bountyUrls.filter(url => url.trim() !== ''), // Filter out empty URLs
            date: new Date().toISOString(), // Current timestamp
            files: bountyFiles.map((file) => {
              return {
                name: file.name
              } 
            }), // File names
            id: report_uuid,
            submittedBy: address, // The address of the user submitting the report
          };

          // Add the new report to the correct report title or create a new entry
          /*
          return {
            ...bounty,
            reports: {
              ...bounty.reports,
              [bountyReportTitle]: [...(bounty.reports[bountyReportTitle] || []), newReport],
            },
          };
          */
          return {
            ...bounty,
            reports: {
              ...bounty.reports,
              [report_uuid]: [...(bounty.reports[bountyReportTitle] || []), newReport],
            },
          };
        }
        return bounty;
      });

      const formData = new FormData();
      // Update state with the newly added report
      setBounties(updatedBounties);

      // Save the updated bounty back to the server
      const bountyToUpdate = updatedBounties.find((bounty) => bounty.bNum === selectedBountyId);
      
      for (const bfile of bountyFiles) {
        formData.append("files", bfile);
      }
      formData.append("report_id", report_uuid);

      let config = {
          headers: {
            Authorization: `${accessToken}`,
            Account: address
          }
      };

      await axios.put(`${process.env.REACT_APP_BACKEND_API_URL}/bounties/${selectedBountyId}`, bountyToUpdate, config);
      

      config = {
          headers: {
            Authorization: `${accessToken}`,
            Account: address,
            'Content-Type': 'multipart/form-data'
          }
      };



      const {data: new_bounty } = await axios.put(`${process.env.REACT_APP_BACKEND_API_URL}/bounties/${selectedBountyId}/files`, formData, config);

      let bounty = Object.values(new_bounty.reports).filter((rs) => {
        return (rs.filter((r) => {     
          return r.id === report_uuid 
        }).length > 0)
      })
      
      let added_files = [];
      if (bounty.length) {
        bounty = bounty[0][0]
        added_files =bounty.files
      }
  
      updatedBounties.map((bounty) => {
        if (bounty.bNum === selectedBountyId) {
          for(const [key, val] of Object.entries(bounty.reports)) {
            if (val.filter((r) => { return r.id === report_uuid }).length > 0) {
              bounty.reports[key] = val.map((r) => {
                if (r.id === report_uuid) {
                  r.files = added_files
                }
                return r
              })
            }
          }
        }
      });


      // Reset the report form
      setBountyReportTitle('');
      setBountyReportText('');
      setBountyUrls(['']);
      setBountyFiles([]);

      triggerNotification('Bounty Report');


    } catch (error) {
      console.error('Error saving bounty report:', error);
      setError('Failed to save bounty report');
    }
  };


  // Function to update the submission log for bounties
  const updateSubmissionLogForBounty = (title, text, urls = [], fileNames = []) => {
    setBounties(prev => ({
      ...prev,
      [selectedBountyId]: [
        ...(prev[selectedBountyId] || []),
        {
          text,
          urls,
          date: new Date().toISOString(),
          files: fileNames,
          title: title,
        },
      ].sort((a, b) => new Date(b.date) - new Date(a.date)), // Sort by date
    }));
  };

  useEffect(() => {
    const fetchBounties = async () => {
      try {
        const config = {
            headers: {
              Authorization: `${accessToken}`,
              Account: address
            }
        };
        
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/bounties`, config);
        setBounties(response.data);
      } catch (error) {
        setError('Failed to fetch bounties');
      }
    };

    fetchBounties();
  }, [address, accessToken]);

  // Close modal if clicked outside
  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      handleCloseTaskModal();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    const fetchProposals = async () => {
      try {
        const config = {
            headers: {
              Authorization: `${accessToken}`,
              Account: address
            }
        };
        
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/data`, config);

        if (response.data) {
          const filteredProposals = response.data.filter(proposal =>
            proposal.benAdd === address || proposal.proposerAdd === address
          );
          setProposals(filteredProposals);
        } else {
          setProposals(response.data);
        }
        
      } catch (error) {
        setError('Failed to fetch proposals (0xB)');
      } finally {
        setLoading(false);
      }
    };

    fetchProposals();
  }, [address, accessToken]);

  useEffect(() => {
    if (selectedProposalId) {
      fetchSubmissionLog(selectedProposalId);
    }
  }, [selectedProposalId]);

  // --------------------------------------------------------------------------------------------------------------------

  // useEffect(() => {

  //   localStorage.removeItem('hasSeenTour');
  //   localStorage.removeItem('hasSeenReportTour');
  //   localStorage.removeItem('hasSeenDeliveredTour');

  //   const startProposalTour = () => {
  //     const intro = introJs();
  //     intro.setOptions({ 
  //       steps: [
  //         {
  //           element: '.proposal-step',
  //           intro: 'View all your approved proposals listed by their REF number, and choose the one you want to submit a report for.',
  //           position: 'right',
  //         }
  //       ],
  //       showStepNumbers: false,
  //       showBullets: true,
  //       nextLabel: 'Next',
  //       prevLabel: 'Back',
  //       doneLabel: 'Got it!',
  //       scrollToElement: true,
  //       disableInteraction: true,
  //     });

  //     // Handle when the tour finishes or is exited
  //     const handleAutoSelectProposal = () => {
  //       const firstAvailableProposal = proposals[0]; // Select the first proposal in the list

  //       if (firstAvailableProposal) {
  //         setSelectedProposalId(firstAvailableProposal.id); // Select the first available proposal no matter the status
  //       }
  //     };

  //     intro.oncomplete(() => {
  //       handleAutoSelectProposal();
  //       localStorage.setItem('hasSeenTour', 'true'); // Mark the tour as seen
  //     });
  //     intro.onexit(() => {
  //       handleAutoSelectProposal();
  //       localStorage.setItem('hasSeenTour', 'true'); // Mark the tour as seen
  //     });
  //     intro.start();
  //   };

  //   // Check if the tour has been seen before starting it
  //   const hasSeenTour = localStorage.getItem('hasSeenTour');
  //   if (!hasSeenTour && proposals.length > 0) {
  //     const timeout = setTimeout(startProposalTour, 1500); // Delay to ensure elements are rendered
  //     return () => clearTimeout(timeout);
  //   }
  // }, [proposals, setSelectedProposalId]);

  // useEffect(() => {
  //   if (selectedProposalId !== null) {
  //     const selectedProposal = proposals.find(proposal => proposal.id === selectedProposalId);

  //     const startDeliveredTour = () => {
  //       const intro = introJs();
  //       intro.setOptions({
  //         steps: [
  //           {
  //             element: '.tab-report',
  //             intro: 'This section consists of 4 fields: Title, Report URL,Comment, and Choose Files where you can provide updates on the status of your proposal.',
  //             position: 'bottom',
  //           },
  //           {
  //             element: '.report-content',
  //             intro: 'Provide your report by filling the input fields and click "Save Report."',
  //             position: 'left',
  //           },
  //           {
  //             element: '.tab-tasks',
  //             intro: 'In this section, you can provide updates and reports for each specific task, as well as request edits for any changes that need to be applied.',
  //             position: 'right',
  //           },
  //           {
  //             element: '.tab-submission-log',
  //             intro: 'View your activity history, including reports, updates or edits submitted through both the "My Report" and My Tasks sections, organized in chronological order.',
  //             position: 'right',
  //           }
  //         ],
  //         showStepNumbers: false,
  //         showBullets: true,
  //         nextLabel: 'Next',
  //         prevLabel: 'Back',
  //         doneLabel: 'Got it!',
  //         scrollToElement: true,
  //         disableInteraction: true,
  //       });

  //       intro.oncomplete(() => {
  //         localStorage.setItem('hasSeenDeliveredTour', 'true'); // Mark the delivered tour as seen
  //       });
  //       intro.onexit(() => {
  //         localStorage.setItem('hasSeenDeliveredTour', 'true'); // Mark the delivered tour as seen
  //       });
  //       intro.start();
  //     };

  //     // Check if the delivered tour has been seen before starting it
  //     const hasSeenDeliveredTour = localStorage.getItem('hasSeenDeliveredTour');
  //     if (!hasSeenDeliveredTour) {
  //       setTimeout(startDeliveredTour, 1000);
  //     }

  //   }
  // }, [selectedProposalId, proposals]);



  // --------------------------------------------------------------------------------------------------------------------

  const fetchSubmissionLog = async (proposalId) => {
      try {
        const config = {
          headers: {
            Authorization: `${accessToken}`,
            Account: address
          }
      };
      
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/data/${proposalId}`, config);
      //const response = await axios.get(`${process.env.REACT_APP_API_URL}/${proposalId}`);
      const proposal = response.data;
      
      const logEntries = [
        ...(proposal.deliverables ? Object.entries(proposal.deliverables).flatMap(([taskKey, entries]) =>
          entries.map(entry => ({
            type: 'Deliverable',
            task: taskKey,
            text: entry.text,
            date: entry.date,
            urls: entry.urls || [],
            url: entry.url || ''
          }))
        ) : []),
        ...(proposal.reports ? Object.entries(proposal.reports).flatMap(([taskKey, entries]) =>
          entries.map(entry => ({
            type: 'Report',
            task: taskKey,
            text: entry.text,
            date: entry.date,
            urls: entry.urls || [],
            url: entry.url || '',
            files: entry.files
          }))
        ) : []),
        ...(proposal.edits ? Object.entries(proposal.edits).flatMap(([taskKey, entries]) =>
          entries.map(entry => ({
            type: 'Edit Request',
            task: taskKey,
            text: entry.text,
            date: entry.date,
          }))
        ) : []),
      ];

      setSubmissionLog(prev => ({
        ...prev,
        [proposalId]: logEntries.sort((a, b) => new Date(b.date) - new Date(a.date))
      }));
    } catch (error) {
      console.error('Failed to fetch submission log:', error);
      setError('Failed to fetch submission log');
    }
  };

  const updateSubmissionLog = (type, text, urls = [], taskKey = null, fileNames = [], title = '') => {
    setSubmissionLog(prev => ({
      ...prev,
      [selectedProposalId]: [
        ...(prev?.[selectedProposalId] || []),
        {
          type,
          text,
          urls,
          task: taskKey,
          date: new Date().toISOString(),
          files: fileNames,
          title: type === 'Report' ? title : undefined,
        },
      ].sort((a, b) => new Date(b.date) - new Date(a.date)), // Sorting by most recent date
    }));
  };




  const handleEditTextChange = (taskKey, value) => {
    setEditTexts(prev => ({
      ...prev,
      [selectedProposalId]: {
        ...prev[selectedProposalId],
        [taskKey]: value
      }
    }));
  };

  const handleSaveEdit = async (proposalId, taskKey) => {
    const editText = editTexts[proposalId]?.[taskKey] || '';

    if (!editText) {
      triggerNotification('Edit', true);
      return;
    }

    try {
      const updatedProposal = proposals.map(proposal => {
        if (proposal.id === proposalId) {
          const existingEdits = proposal.edits || {};
          const newEdit = {
            text: editText,
            date: new Date().toISOString(),
            submittedBy: address, // Include the submitter's address
          };
          return {
            ...proposal,
            edits: {
              ...existingEdits,
              [taskKey]: [...(existingEdits[taskKey] || []), newEdit],
            },
          };
        }
        return proposal;
      });

      setProposals(updatedProposal);
      setEditingTask(prev => ({
        ...prev,
        [taskKey]: false,
      }));

      const config = {
          headers: {
            Authorization: `${accessToken}`,
            Account: address
          }
      };
    
      await axios.put(`${process.env.REACT_APP_BACKEND_API_URL}/data/${proposalId}`, {
        ...updatedProposal.find((p) => p.id === proposalId),
      }, config);


      updateSubmissionLog('Edit Request', editText, [], taskKey);
      fetchSubmissionLog(proposalId);
      triggerNotification('Edit');
    } catch (error) {
      console.error('Error saving edit:', error);
      setError('Failed to save edit');
    }
  };

  const handleUrlChange = (index, value) => {
    const updatedUrls = [...urls];
    updatedUrls[index] = value;
    setUrls(updatedUrls);
  };

  const addNewUrlField = () => {
    setUrls([...urls, '']);
  };



  const handleSaveReport = async () => {
    if (!selectedProposalId || (reportTitle.trim() === '' && reportText.trim() === '' && urls.every(url => url.trim() === '') && files.length === 0)) {
      triggerNotification('Report', true);
      return;
    }

    
    const report_uuid = uuidv4();

    try {
      const updatedProposal = proposals.map((proposal) => {
        if (proposal.id === selectedProposalId) {
          const existingReports = proposal.reports || {};
          const newReport = {
            title: reportTitle, // Keep title for the report
            text: reportText,
            urls: urls.filter(url => url.trim() !== ''), // Filter out empty URLs
            date: new Date().toISOString(),
            files: files.map((file) => {
              return {
                name: file.name
              } 
            }), // Include file names in the report
            id: report_uuid,
            submittedBy: address, // Include the submitter's address
          };

          return {
            ...proposal,
            reports: {
              ...existingReports,
              [reportTitle]: [...(existingReports[reportTitle] || []), newReport],
            },
          };
        }
        return proposal;
      });

      setProposals(updatedProposal);
      
      const formData = new FormData();

   
      for (const pfile of files) {
        formData.append("files", pfile);
      }
      formData.append("report_id", report_uuid);
      let config = {
          headers: {
            Authorization: `${accessToken}` ,
            Account: address
          }
      };

      await axios.put(`${process.env.REACT_APP_BACKEND_API_URL}/data/${selectedProposalId}`, {
        ...updatedProposal.find((p) => p.id === selectedProposalId),
      }, config);
    
      config = {
          headers: {
            Authorization: `${accessToken}` ,
            Account: address,
            'Content-Type': 'multipart/form-data'
          }
      };

      const {data: new_proposal } = await axios.put(`${process.env.REACT_APP_BACKEND_API_URL}/data/${selectedProposalId}/files`, formData, config);

      const report = Object.values(new_proposal.reports).filter((rs) => {
        return (rs.filter((r) => {     
          return r.id === report_uuid 
        }).length > 0)
      })
      
      let added_files = [];
      if (report.length) {
        added_files = report[0][0].files
      }
      
      
      updateSubmissionLog('Report', reportText, urls.filter(url => url.trim() !== ''), null, added_files, reportTitle); // Pass the title to the log
      setReportTitle(''); // Clear title after submission
      setReportText(''); // Clear text after submission
      setUrls(['']); // Clear URLs after submission
      setFiles([]); // Clear files after submission


      triggerNotification('Report');
    } catch (error) {
      console.error('Error saving report:', error);
      setError('Failed to save report');
    }
  };





  const triggerNotification = (type, isError = false) => {
    setNotification({
      message: isError ? 'No content to submit' : `Your ${type} has been saved`,
      isError: isError
    });

    setTimeout(() => {
      setNotification(null);
    }, 4000);
  };

  const taskTitleMap = (proposal) => {
    const titles = {};
    if (proposal) {
      Object.keys(proposal)
        .filter(key => key.startsWith('task') && proposal[key])
        .forEach(taskKey => {
          titles[taskKey] = proposal[taskKey];
        });
      }
    return titles;
  };

  const handleOpenTaskModal = (taskKey) => {
    setSelectedTaskKey(taskKey);
    setTaskModalOpen(true);
  };

  const handleCloseTaskModal = () => {
    setTaskModalOpen(false);
    setSelectedTaskKey(null);
  };

  const handleDeliverableTextChange = (taskKey, value) => {
    setDeliverableText(prev => ({
      ...prev,
      [taskKey]: value,
    }));
  };

  const handleSaveDeliverable = async (proposalId, taskKey) => {
    const text = deliverableText[taskKey];

    if (!text) {
      triggerNotification('Deliverable', true);
      return;
    }

    try {
      const updatedProposal = proposals.map(proposal => {
        if (proposal.id === proposalId) {
          const existingDeliverables = proposal.deliverables || {};
          const newDeliverable = {
            text: text,
            date: new Date().toISOString(),
            submittedBy: address, // Include the submitter's address
          };
          return {
            ...proposal,
            deliverables: {
              ...existingDeliverables,
              [taskKey]: [...(existingDeliverables[taskKey] || []), newDeliverable],
            },
          };
        }
        return proposal;
      });

      setProposals(updatedProposal);
      setDeliverableText(prev => ({
        ...prev,
        [taskKey]: '',
      }));

      const config = {
          headers: {
            Authorization: `${accessToken}`,
            Account: address
          }
      };
    
      await axios.put(`${process.env.REACT_APP_BACKEND_API_URL}/data/${proposalId}`, {
        ...updatedProposal.find((p) => p.id === proposalId),
      }, config);

      /*
      await axios.put(`${process.env.REACT_APP_API_URL}/${proposalId}`, {
        ...updatedProposal.find((p) => p.id === proposalId),
      });
      */


      updateSubmissionLog('Deliverable', text, [], taskKey);
      fetchSubmissionLog(proposalId);

      triggerNotification('Deliverable');
      handleCloseTaskModal();
    } catch (error) {
      console.error('Error saving deliverable:', error);
      setError('Failed to save deliverable');
    }
  };

  const filteredLogs = selectedTask
    ? submissionLog[selectedProposalId]?.filter(log => log.task === selectedTask) || []
    : submissionLog[selectedProposalId] || [];

  if (loading) {
    return <div>Loading proposals...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (

    <div className="flex flex-row min-h-screen pt-[60px] sm:px-8 px-4 relative">
      {/* Notification Popup */}
      {notification && (
        <div className="fixed top-4 right-4 text-center bg-kog-800 pt-4 shadow-lg flex flex-col space-y-2 w-64 z-50">
          <div className={`text-white mb-2 ${notification.isError ? 'text-white' : 'text-white'}`}>
            {notification.message}
          </div>
          <div className="h-1 bg-gray-200 relative w-full ">
            <div className="absolute left-0 bottom-0 h-1 bg-kog-100 w-full animate-progress-bar"></div>
          </div>
        </div>
      )}

      {/* Sidebar for Desktop */}
      {/* Sidebar for Desktop */}
      <div
        className="w-full h-[calc(100vh-75px)] sticky top-[60px] hidden lg:flex flex-col justify-between p-4 pb-6 bg-white dark:bg-kog-999 backdrop-filter backdrop-blur-xl bg-opacity-40 shadow-[inset_0px_0px_10px_5px_rgba(0,0,0,0.4)] rounded-xl max-w-[240px] border border-kog-500 overflow-y-auto proposal-step"
      >
        <h2 className="text-xl text-kog-100 dark:text-white text-center font-bold mb-6">My Proposals</h2>
        <div className="flex-1 flex flex-col justify-center">
          <div className="flex flex-col items-center justify-center">
            {/* Sort proposals based on status */}
            {proposals
              .sort((a, b) => {
                const statusOrder = { InProgress: 1, Flagged: 2, Delivered: 3, Executed: 3 };
                return (statusOrder[a.status] || 4) - (statusOrder[b.status] || 4);
              })
              .filter(
                (proposal) =>
                  !bounties.some(
                    (bounty) =>
                      bounty.rRefs &&
                      bounty.rRefs
                        .split(',')
                        .map((ref) => ref.trim())
                        .includes(proposal.refNum)
                  )
              )
              .map((proposal) => {
                // Function to determine the correct icon based on the proposal status
                const getStatusIcon = (status) => {
                  switch (status) {
                    case 'InProgress':
                      return (
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M6 2h12v6l-4 4l4 4v6H6v-6l4-4l-4-4zm10 14.5l-4-4l-4 4V20h8zm-4-5l4-4V4H8v3.5zM10 6h4v.75l-2 2l-2-2z"
                          />
                        </svg>
                      );
                    case 'Delivered':
                    case 'Executed':
                      return (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="lucide lucide-check"
                        >
                          <path d="M20 6 9 17l-5-5"></path>
                        </svg>
                      );
                    case 'Flagged':
                      return (
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 56 56">
                          <path
                            fill="currentColor"
                            d="M8.969 51.004c.984 0 1.781-.773 1.781-1.781V36.215c.586-.258 2.79-1.102 6.234-1.102c8.672 0 14.11 4.243 22.36 4.243c3.656 0 5.015-.399 6.797-1.196c1.617-.726 2.672-1.945 2.672-4.101V10.246c0-1.242-1.079-1.992-2.438-1.992c-1.148 0-3.305 1.008-7.336 1.008c-8.25 0-13.664-4.266-22.36-4.266c-3.656 0-5.038.398-6.82 1.195c-1.617.727-2.671 1.97-2.671 4.102v38.93c0 .96.82 1.78 1.78 1.78m30.375-15.211c-7.805 0-13.336-4.219-22.36-4.219c-2.578 0-4.898.305-6.234.844v-22.03c.328-.75 2.273-1.828 5.93-1.828c8.25 0 13.758 4.242 22.36 4.242c2.554 0 4.663-.305 6.233-.774v21.938c-.328.75-2.273 1.828-5.93 1.828"
                          />
                        </svg>
                      );
                    default:
                      return null;
                  }
                };

                return (
                  <button
                    key={proposal.id}
                    data-proposal-id={proposal.id}
                    className={`p-2 rounded flex items-center justify-between font-bold text-2xl border border-kog-300 w-full text-kog-100 dark:text-white mb-2 ${selectedProposalId === proposal.id
                      ? 'bg-gradient-to-t from-kog-900 to-kog-900 hover:from-kog-900 hover:to-kog-900 shadow-kog-900 shadow-[0px_0px_4px_1px_rgba(0,0,0,0.5)] border-none'
                      : 'bg-gradient-to-t hover:from-kog-900 dark:hover:from-kog-300 dark:hover:to-kog-900'
                      }`}
                    onClick={() => {
                      setSelectedProposalId(proposal.id);
                      setSelectedBountyId(null);
                    }}
                  >
                    {/* Left side: refNum */}
                    <span className="text-left">#{proposal.refNum}</span>

                    {/* Right side: status with icon or "Tip" */}
                    <span className="flex items-center space-x-2">
                      <span>
                        {proposal.category === 'Tip' ? (
                          <span className="text-[1rem] font-bold tracking-wide">TIP</span>
                        ) : (
                          getStatusIcon(proposal.status)
                        )}
                      </span>
                    </span>
                  </button>
                );
              })}

            {/* Display bounties based on matching refNum or matching curators address */}
            {bounties
              .filter((bounty) => {
                const matchesRef =
                  bounty.rRefs &&
                  bounty.rRefs
                    .split(',')
                    .map((ref) => ref.trim())
                    .some((ref) => proposals.some((p) => p.refNum === ref));
                const matchesCurator = bounty.curators && bounty.curators === address;
                return matchesRef || matchesCurator;
              })
              .map((bounty) => (
                <button
                  key={bounty.bNum}
                  className={`p-2 rounded flex items-center justify-between font-bold text-2xl border border-kog-300 w-full text-kog-100 dark:text-white mb-2 ${selectedBountyId === bounty.bNum
                    ? 'bg-gradient-to-t from-kog-900 to-kog-900 hover:from-kog-900 hover:to-kog-900 shadow-kog-900 shadow-[0px_0px_4px_1px_rgba(0,0,0,0.5)] border-none'
                    : 'hover:bg-kog-400'
                    }`}
                  onClick={() => {
                    setSelectedBountyId(bounty.bNum);
                    setSelectedProposalId(null); // Clear proposal selection
                  }}
                >
                  {/* Left side: bNum */}
                  <span className="text-left">#{bounty.bNum}</span>

                  {/* Right side: label "Bounty" */}
                  <span className="text-[1rem]">BOUNTY</span>
                </button>
              ))}
          </div>
        </div>
      </div>


      {/* Main Content */}
      <div className="flex-grow w-full">
        {/* Proposal Stats */}
        {proposals.length > 0 && (
          <div className="w-full p-4 bg-white dark:bg-kog-999 md:mx-4 rounded-lg border border-kog-500 backdrop-filter backdrop-blur-xl bg-opacity-40 shadow-[inset_0px_0px_10px_5px_rgba(0,0,0,0.4)] mb-4">
            <ProposalStats address={address} accessToken={accessToken}/>
          </div>
        )}
        {/* Mobile Sidebar Tabs */}
        <div className="lg:hidden w-full p-4 bg-white dark:bg-kog-999 my-4 md:mx-4 rounded-lg border border-kog-500 backdrop-filter backdrop-blur-xl bg-opacity-40 shadow-[inset_0px_0px_10px_5px_rgba(0,0,0,0.4)]">

          <h2 className="text-xl text-kog-100 dark:text-white text-center font-bold mb-6">My Proposals</h2>
          <div className="grid grid-cols-2 gap-4">
            {proposals
              .filter((proposal) => proposal.status === 'InProgress' || proposal.status === 'Delivered' || proposal.status === 'Executed' || proposal.status === 'Flagged')
              .map((proposal) => (
                <button
                  key={proposal.id}
                  className={`p-2 rounded text-center font-bold text-lg border border-kog-300 w-full text-kog-100 dark:text-white mb-2 shadow-[0px_0px_4px_1px_rgba(0,0,0,0.5)] ${selectedProposalId === proposal.id
                    ? 'bg-gradient-to-t from-kog-900 to-kog-900 hover:from-kog-900 hover:to-kog-900 shadow-kog-900 shadow-[0px_0px_4px_1px_rgba(0,0,0,0.5)] border-none'
                    : 'bg-gradient-to-t hover:from-kog-900 dark:hover:from-kog-300 dark:hover:to-kog-900'
                    }`}
                  onClick={() => setSelectedProposalId(proposal.id)}
                >
                  #{proposal.refNum}
                </button>
              ))}
          </div>
        </div>


        {/* Main Panel with Tabs */}
        <div className="w-full p-4 bg-white mb-4 bg-opacity-40 dark:bg-kog-999 md:mx-4 rounded-lg border border-kog-500 backdrop-filter backdrop-blur-sm dark:bg-opacity-10 shadow-[inset_0px_0px_10px_5px_rgba(0,0,0,0.3)]">

          {selectedBountyId ? (
            <div className='h-[87vh]'>
              <Tabs selectedIndex={selectedTab} onSelect={(index) => setSelectedTab(index)}>
                <TabList className="tabs-container flex space-x-1 sm:space-x-4 mb-4">
                  <Tab
                    selectedClassName="bg-gradient-to-t from-kog-700 to-kog-900 shadow-kog-900 text-white"
                    className="tab-report px-4 py-2 rounded-full text-kog-100 dark:text-white dark:bg-kog-200 shadow-[0px_0px_5px_2px_rgba(0,0,0,0.3)] dark:shadow-[0px_0px_10px_1px_rgba(0,0,0,0.7)] text-black transition-all cursor-pointer border border-kog-800 dark:border-kog-900 dark:border-opacity-30"
                  >
                    My Report
                  </Tab>

                  <Tab
                    selectedClassName="bg-gradient-to-t from-kog-700 to-kog-900 shadow-kog-900 text-white"
                    className="tab-submission-log px-4 py-2 rounded-full text-kog-100 dark:text-white dark:bg-kog-200 shadow-[0px_0px_5px_2px_rgba(0,0,0,0.3)] dark:shadow-[0px_0px_10px_1px_rgba(0,0,0,0.7)] text-black transition-all cursor-pointer border border-kog-800 dark:border-kog-900 dark:border-opacity-30"
                  >
                    Submission Log
                  </Tab>
                </TabList>

                {/* Report Tab Panel */}
                <TabPanel>
                  {/* Bounty Form */}
                  <div className="hidden sm:flex absolute top-2 right-2 text-gray-200 ">
                    <div>
                      <span className="font-bold text-xl text-white">Related Referenda: </span>
                      {selectedBounty?.rRefs ? (
                        selectedBounty.rRefs.split(',').map(ref => (
                          <span
                            key={ref}
                            className="px-3 mx-1 py-1 bg-kog-300 text-white rounded-full shadow-md border border-kog-400 border-opacity-50"
                          >
                            #{ref.trim()}
                          </span>
                        ))
                      ) : (
                        <span className="px-3 mx-1 py-1 bg-kog-300 text-white rounded-full shadow-md border border-kog-400 border-opacity-50">Gov1</span>
                      )}
                    </div>
                  </div>

                  <div className="p-4 rounded w-full max-w-[732px] mx-auto">
                    <label className="block text-2xl font-bold text-kog-300 dark:text-white">Report</label>
                    <input
                      type="text"
                      value={bountyReportTitle}
                      onChange={(e) => setBountyReportTitle(e.target.value)}
                      className="bg-gray-200 border border-kog-700 dark:border-none dark:bg-kog-300 text-white shadow-[0px_6px_10px_1px_rgba(241,26,230,0.2)] dark:shadow-[0px_6px_10px_1px_rgba(0,0,0,0.2)] p-4 rounded w-full"
                      placeholder="Describe the type of your report."
                    />

                    <label className="block mt-5 text-2xl font-bold text-kog-300 dark:text-white">Report URL</label>
                    {bountyUrls.map((url, index) => (
                      <div key={index} className="flex items-center mb-2">
                        <input
                          type="text"
                          value={url}
                          onChange={(e) => {
                            const newUrls = [...bountyUrls];
                            newUrls[index] = e.target.value;
                            setBountyUrls(newUrls);
                          }}
                          className="bg-gray-200 border border-kog-700 dark:border-none dark:bg-kog-300 text-white shadow-[0px_6px_10px_1px_rgba(241,26,230,0.2)] dark:shadow-[0px_6px_10px_1px_rgba(0,0,0,0.2)] p-4 rounded w-full"
                          placeholder="Google Doc, GitHub, PDF, etc."
                        />
                        {index === bountyUrls.length - 1 && (
                          <button onClick={() => setBountyUrls([...bountyUrls, ''])} className="ml-2 text-xl text-white bg-kog-900 hover:animate-plus hover:shadow-[0px_0px_10px_1px_rgba(0,0,0,0.3)] p-2 rounded-full">
                            <IoMdAdd />
                          </button>
                        )}
                      </div>
                    ))}

                    <label className="block text-2xl font-bold text-kog-300 dark:text-white mt-5">Comment</label>
                    <textarea
                      value={bountyReportText}
                      onChange={(e) => setBountyReportText(e.target.value)}
                      className="bg-gray-200 border border-kog-700 dark:border-none dark:bg-kog-300 text-white shadow-[0px_6px_10px_1px_rgba(241,26,230,0.2)] dark:shadow-[0px_6px_10px_1px_rgba(0,0,0,0.2)] p-4 rounded w-full"
                      style={{ height: '200px' }}
                      placeholder="Please share any comments relevant to your report"
                    />

                    <FileUpload setFiles={setBountyFiles} />

                    <button
                      onClick={handleBountySaveReport}
                      className="bg-gradient-to-t from-kog-300 to-kog-900 hover:from-kog-900 hover:to-kog-900 border border-kog-400 text-white px-4 py-2 rounded-full text-3xl font-bold w-full mt-8 hover:shadow-[0px_0px_10px_5px_rgba(0,0,0,0.3)] hover:bg-kog-900"
                    >
                      Save Bounty Report
                    </button>
                  </div>
                </TabPanel>
                {/* Submission Log Tab Panel */}
                <TabPanel>
                  <div className="grid grid-cols-1 gap-4">
                    {/* Check if reports exist for the selected bounty */}
                    {selectedBounty?.reports ? (
                      Object.entries(selectedBounty.reports)
                        .flatMap(([reportId, entries]) =>
                          entries
                            .map((log) => ({
                              ...log,
                              reportId, // Include the title for use in the render
                            }))
                        )
                        .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date, newest first
                        .map((log, index) => (
                          <div key={index} className="relative p-4 border text-kog-100 dark:text-white border-kog-400 border-opacity-30 shadow-[0px_2px_7px_2px_rgba(0,0,0,0.3)] rounded">
                            {/* Type Capsule */}
                            <span className="absolute bottom-3 md:top-3 h-7 right-2 px-3 py-1 rounded-full text-white shadow-xl text-sm font-bold bg-gradient-to-t from-blue-900 dark:from-kog-300 to-blue-500 dark:to-blue-500">
                              Report
                            </span>

                            {/* Display Report Title */}
                            <div>
                              <strong>Title:</strong> {log.title}
                            </div>

                            {/* Display Report Comment */}
                            <div>
                              <strong>Comment:</strong> {log.text}
                            </div>

                            {/* Display URLs if available */}
                            {log.urls?.length > 0 && (
                              <div>
                                <strong>URLs:</strong>
                                <ul className="list-disc ml-5">
                                  {log.urls.map((url, urlIndex) => (
                                    <li key={urlIndex}>
                                      <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                                        {url}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}

                            {/* Display Files if available */}
                            {log.files?.length > 0 && (
                              <div>
                                <strong>Files:</strong>
                                <ul className="list-disc ml-5">
                                  {log.files.map((file, fileIndex) => (
                                    <li key={fileIndex}><a target='_blank' href={`${process.env.REACT_APP_BACKEND_API_URL}/${file.file}`}>{file.name}</a></li>
                                  ))}
                                </ul>
                              </div>
                            )}

                            {/* Display Date */}
                            <div>
                              <strong>Date:</strong> {new Date(log.date).toLocaleString()}
                            </div>
                          </div>
                        ))
                    ) : (
                      <div>No reports available for this bounty.</div>
                    )}
                  </div>
                </TabPanel>




              </Tabs>


            </div>
          ) : selectedProposalId ? (
            <Tabs selectedIndex={selectedTab} onSelect={(index) => setSelectedTab(index)}>
              <TabList className="tabs-container flex space-x-1 sm:space-x-4 mb-4">
                <Tab
                  selectedClassName="bg-gradient-to-t from-kog-700 to-kog-900 shadow-kog-900 text-white"
                  className="tab-report px-4 py-2 rounded-full text-kog-100 dark:text-white dark:bg-kog-200 shadow-[0px_0px_5px_2px_rgba(0,0,0,0.3)] dark:shadow-[0px_0px_10px_1px_rgba(0,0,0,0.7)] text-black transition-all cursor-pointer border border-kog-800 dark:border-kog-900 dark:border-opacity-30"
                >
                  My Report
                </Tab>
                <Tab
                  selectedClassName="bg-gradient-to-t from-kog-700 to-kog-900 shadow-kog-900 text-white"
                  className="tab-tasks px-4 py-2 rounded-full text-kog-100 dark:text-white dark:bg-kog-200 shadow-[0px_0px_5px_2px_rgba(0,0,0,0.3)] dark:shadow-[0px_0px_10px_1px_rgba(0,0,0,0.7)] text-black transition-all cursor-pointer border border-kog-800 dark:border-kog-900 dark:border-opacity-30"
                >
                  My Tasks
                </Tab>
                <Tab
                  selectedClassName="bg-gradient-to-t from-kog-700 to-kog-900 shadow-kog-900 text-white"
                  className="tab-submission-log px-4 py-2 rounded-full text-kog-100 dark:text-white dark:bg-kog-200 shadow-[0px_0px_5px_2px_rgba(0,0,0,0.3)] dark:shadow-[0px_0px_10px_1px_rgba(0,0,0,0.7)] text-black transition-all cursor-pointer border border-kog-800 dark:border-kog-900 dark:border-opacity-30"
                >
                  Submission Log
                </Tab>
              </TabList>

              {/* Report Tab Panel */}
              <TabPanel>
                <div className="flex justify-center items-center">
                  <div className="hidden sm:flex absolute top-2 right-2 text-kog-100 dark:text-gray-200 text-2xl bg-gradient-to-t from-kog-950 dark:from-kog-400 dark:to-kog-50 px-3 rounded-full border border-kog-400 font-bold shadow-[0px_0px_10px_2px_rgba(0,0,0,0.3)]">
                    {selectedProposalId && proposals.find(p => p.id === selectedProposalId)?.refNum
                      ? `#${proposals.find(p => p.id === selectedProposalId).refNum}`
                      : ''}
                  </div>
                  <div className="p-4 rounded w-full max-w-[732px] report-content">
                    <label className="block text-2xl font-bold text-kog-300 dark:text-white">Title</label>
                    <input
                      type="text"
                      value={reportTitle}
                      onChange={(e) => setReportTitle(e.target.value)}
                      className="bg-gray-200 border border-kog-700 dark:border-none dark:bg-kog-300 text-white shadow-[0px_6px_10px_1px_rgba(241,26,230,0.2)] dark:shadow-[0px_6px_10px_1px_rgba(0,0,0,0.2)] p-4 rounded w-full"
                      placeholder="Describe the type of your report. (eg. Milestone 1, Monthly Report, Final Report)"
                    />

                    <label className="block mt-5 text-2xl font-bold text-kog-300 dark:text-white">Report URL</label>
                    {urls.map((url, index) => (
                      <div key={index} className="flex items-center mb-2">
                        <input
                          type="text"
                          value={url}
                          onChange={(e) => handleUrlChange(index, e.target.value)}
                          className="bg-gray-200 border border-kog-700 dark:border-none dark:bg-kog-300 text-white shadow-[0px_6px_10px_1px_rgba(241,26,230,0.2)] dark:shadow-[0px_6px_10px_1px_rgba(0,0,0,0.2)] p-4 rounded w-full"
                          placeholder="Google Doc, GitHub, PDF, etc."
                        />
                        {index === urls.length - 1 && (
                          <button onClick={addNewUrlField} className="ml-2 text-xl text-white bg-kog-900 hover:animate-plus hover:shadow-[0px_0px_10px_1px_rgba(0,0,0,0.3)] p-2 rounded-full">
                            <IoMdAdd />
                          </button>
                        )}
                      </div>
                    ))}

                    <label className="block text-2xl font-bold text-kog-300 dark:text-white mt-5">Comment</label>
                    <textarea
                      value={reportText}
                      onChange={(e) => setReportText(e.target.value)}
                      className="bg-gray-200 border border-kog-700 dark:border-none dark:bg-kog-300 text-white shadow-[0px_6px_10px_1px_rgba(241,26,230,0.2)] dark:shadow-[0px_6px_10px_1px_rgba(0,0,0,0.2)] p-4 rounded w-full"
                      style={{ height: '200px' }}
                      placeholder="Please share any comments relevant to your report"
                    />

                    <FileUpload setFiles={setFiles} />

                    <button
                      onClick={handleSaveReport}
                      className="bg-gradient-to-t from-kog-300 to-kog-900 hover:from-kog-900 hover:to-kog-900  border border-kog-400 text-white px-4 py-2 rounded-full text-3xl font-bold w-full mt-8 hover:shadow-[0px_0px_10px_5px_rgba(0,0,0,0.3)] hover:bg-kog-900"
                    >
                      Save Report
                    </button>
                  </div>
                </div>
              </TabPanel>

              {/* Tasks Tab Panel */}
              <TabPanel>
                <div className="space-y-2 overflow-auto">
                  <div className="hidden sm:flex absolute top-2 right-2 text-gray-200 text-2xl bg-gradient-to-b from-kog-50 to-kog-400 px-3 rounded-full border border-kog-400 font-bold shadow-[0px_0px_10px_2px_rgba(0,0,0,0.3)]">
                    {selectedProposalId && proposals.find(p => p.id === selectedProposalId)?.refNum
                      ? `#${proposals.find(p => p.id === selectedProposalId).refNum}`
                      : ''}
                  </div>
                  {proposals
                    .filter(p => p.id === selectedProposalId)
                    .map(proposal => {
                      const tasksInProgress = Object.keys(proposal)
                        .filter(key => key.startsWith('task') && proposal[key] && proposal[`status${key.slice(4)}`] === "B");

                      const tasksDelivered = Object.keys(proposal)
                        .filter(key => key.startsWith('task') && proposal[key] && proposal[`status${key.slice(4)}`] === "A");

                      const hasTasks = tasksInProgress.length > 0 || tasksDelivered.length > 0;
                      const isExecuted = proposal.status === "Executed";

                      return (
                        <div key={proposal.id}>
                          {/* Check if the proposal is Executed and has no tasks */}
                          {isExecuted && !hasTasks ? (
                            <div className="text-center text-xl font-bold text-white bg-gradient-to-t from-kog-300 to-kog-900 p-4 rounded-lg border border-opacity-80 border-kog-800">
                              This proposal has no tasks.
                            </div>
                          ) : (
                            <>
                              {tasksInProgress.map(taskKey => (
                                <div
                                  key={taskKey}
                                  className="flex flex-col mb-4 backdrop-blur-xl  text-kog-100 dark:text-white bg-gradient-to-t from-gray-300   dark:from-kog-300 dark:bg-opacity-10 shadow-[inset_0px_0px_5px_5px_rgba(241,26,230,0.3)]  dark:shadow-[inset_0px_0px_5px_5px_rgba(241,26,230,0.1)] p-4 rounded-lg border border-opacity-80 border-kog-600 dark:border-kog-800"
                                >
                                  <div className="flex items-center lg:flex-row flex-col">
                                    {/* SVG Icon on Top for Mobile */}
                                    <div className="lg:order-3 order-1 text-2xl border-kog-300 text-kog-white px-2 py-2 rounded-full mx-1 p-1 shadow-[0px_0px_10px_5px_rgba(0,0,0,0.4)] bg-gradient-to-t from-kog-300 to-yellow-500 ml-3 lg:ml-0 mb-4 lg:mb-0 lg:text-right text-center lg:w-auto">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                        <g>
                                          <path fill="currentColor" d="M7 3H17V7.2L12 12L7 7.2V3Z">
                                            <animate id="eosIconsHourglass0" fill="freeze" attributeName="opacity" begin="0;eosIconsHourglass1.end" dur="2s" from="1" to="0" />
                                          </path>
                                          <path fill="currentColor" d="M17 21H7V16.8L12 12L17 16.8V21Z">
                                            <animate fill="freeze" attributeName="opacity" begin="0;eosIconsHourglass1.end" dur="2s" from="0" to="1" />
                                          </path>
                                          <path fill="currentColor" d="M6 2V8H6.01L6 8.01L10 12L6 16L6.01 16.01H6V22H18V16.01H17.99L18 16L14 12L18 8.01L17.99 8H18V2H6ZM16 16.5V20H8V16.5L12 12.5L16 16.5ZM12 11.5L8 7.5V4H16V7.5L12 11.5Z" />
                                          <animateTransform id="eosIconsHourglass1" attributeName="transform" attributeType="XML" begin="eosIconsHourglass0.end" dur="0.5s" from="0 12 12" to="180 12 12" type="rotate" />
                                        </g>
                                      </svg>
                                    </div>

                                    {/* Task Title and Edit Input */}
                                    <div className="lg:order-1 order-2 w-full text-center lg:text-left mb-4 lg:mb-0">
                                      <p className="flex lg:justify-start justify-center items-center">
                                        <span className="mr-2">{taskTitleMap(proposal)[taskKey]}</span>
                                        {editingTask[taskKey] ? (
                                          <>
                                            <input
                                              type="text"
                                              value={editTexts[proposal.id]?.[taskKey] || ''}
                                              onChange={(e) => handleEditTextChange(taskKey, e.target.value)}
                                              placeholder="Edit this task"
                                              className="bg-kog-300 text-white shadow-[0px_0px_10px_2px_rgba(0,0,0,0.3)] p-2 rounded w-full lg:w-[60%] mr-2"
                                            />
                                            {editTexts[proposal.id]?.[taskKey] ? (
                                              <AiOutlineSave
                                                onClick={() => {
                                                  handleSaveEdit(proposal.id, taskKey);
                                                  handleEditTextChange(taskKey, '');
                                                }}
                                                className="text-2xl cursor-pointer"
                                              />
                                            ) : (
                                              <AiOutlineClose
                                                onClick={() => {
                                                  setEditingTask((prev) => ({ ...prev, [taskKey]: false }));
                                                  handleEditTextChange(taskKey, '');
                                                }}
                                                className="text-2xl cursor-pointer"
                                              />
                                            )}
                                          </>
                                        ) : (
                                          <AiOutlineEdit
                                            onClick={() => setEditingTask((prev) => ({ ...prev, [taskKey]: true }))}
                                            className="text-3xl text-white dark:text-white cursor-pointer border border-kog-400 rounded hover:from-kog-300 hover:to-kog-900 p-1 mr-2 bg-gradient-to-t from-kog-300 to-blue-400 edit-task-step"
                                          />
                                        )}
                                      </p>
                                    </div>

                                    {/* Add Deliverable Button - Full width on mobile */}
                                    <div className="lg:order-2 order-3 w-full max-w-[230px] flex justify-center lg:justify-end relative">
                                      <button
                                        onClick={() => handleOpenTaskModal(taskKey)}
                                        className="text-white px-4 py-2 w-full lg:w-auto animate-glow mx-3 bg-kog-300 hover:bg-kog-800 border border-kog-900 rounded-full text-center add-deliverable-step relative"
                                        onMouseEnter={(e) => {
                                          const tooltip = e.currentTarget.nextElementSibling;
                                          tooltip.style.opacity = '1';
                                          tooltip.style.transform = 'translateX(-10px)';
                                        }}
                                        onMouseLeave={(e) => {
                                          const tooltip = e.currentTarget.nextElementSibling;
                                          tooltip.style.opacity = '0';
                                          tooltip.style.transform = 'translateX(0)';
                                        }}
                                      >
                                        Add Deliverable
                                      </button>

                                      {/* Tooltip */}
                                      <div className="tooltip absolute left-[-10px] top-[7px] transform -translate-y-1/2 opacity-0 transition-opacity duration-300 ease-out translate-x-0 transition-transform bg-kog-900 text-white px-3 py-1 rounded shadow-lg">
                                        Optional
                                        <div className="tooltip-arrow absolute top-1/2 left-full transform translate-x-full -translate-y-1/2 w-0 h-0 border-r-8 border-r-kog-900 border-t-8 border-t-transparent border-b-8 border-b-transparent"></div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}

                              {tasksDelivered.map(taskKey => (
                                <div
                                  key={taskKey}
                                  className="flex items-center  mb-2 backdrop-blur-xl shadow-[inset_0px_0px_5px_5px_rgba(241,26,230,0.1)] p-4 rounded-lg border border-opacity-80 border-kog-800 bg-gradient-to-t from-kog-800  dark:from-kog-400 dark:to-kog-100"
                                >
                                  <div className="w-4/5 mr-2 text-kog-100 dark:text-white font-bold">{taskTitleMap(proposal)[taskKey]}</div>
                                  <div className="w-1/5 flex justify-end">
                                    <div className='text-2xl border-kog-300 text-kog-white px-2 py-2 rounded-full mx-1 p-1 shadow-[0px_0px_10px_5px_rgba(255,255,255,0.3)] dark:shadow-[0px_0px_10px_5px_rgba(0,0,0,0.3)] bg-gradient-to-t from-kog-300 to-green-600 ml-3'>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" strokeLinecap="round" strokeLinejoin="round" class="lucide lucide-check">
                                        <path d="M20 6 9 17l-5-5"></path>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      );
                    })}
                </div>
              </TabPanel>

              {/* Submission Log Tab Panel */}
              <TabPanel>
                <div className="grid grid-cols-1 gap-4">
                  <div className="hidden sm:flex absolute top-2 right-2 text-gray-200 text-2xl bg-gradient-to-b from-kog-50 to-kog-400 px-3 rounded-full border border-kog-400 font-bold shadow-[0px_0px_10px_2px_rgba(0,0,0,0.3)]">
                    {selectedProposalId && proposals.find(p => p.id === selectedProposalId)?.refNum
                      ? `#${proposals.find(p => p.id === selectedProposalId).refNum}`
                      : ''}
                  </div>
                  {filteredLogs.map((log, index) => (
                    <div key={index} className="relative p-4 border text-kog-100 dark:text-white border-kog-400 border-opacity-30 shadow-[0px_2px_7px_2px_rgba(0,0,0,0.3)] rounded">
                      {/* Type Capsule */}
                      <span
                        className={`absolute bottom-3 md:top-3 h-7 right-2 px-3 py-1 rounded-full text-white shadow-xl text-sm font-bold ${log.type === 'Report' ? 'bg-gradient-to-t from-blue-900 dark:from-kog-300 to-blue-500 dark:to-blue-500' : log.type === 'Edit Request' ? 'bg-gradient-to-t from-orange-900 dark:from-kog-300 to-orange-500 dark:to-orange-500' : 'bg-gradient-to-t from-green-900 dark:from-kog-300 to-green-500 dark:to-green-500'}`}
                      >
                        {log.type}
                      </span>

                      {/* Display Title or Task */}
                      <div>
                        <strong>{log.type === 'Report' ? 'Title:' : 'Task:'}</strong>{' '}
                        {log.type === 'Report' && log.title ? log.title : taskTitleMap(proposals.find(p => p.id === selectedProposalId))[log.task] || log.task}
                      </div>

                      {/* Display Report, Input Text, or Edit Request */}
                      <div>
                        <strong>{log.type === 'Report' ? 'Comment:' : log.type === 'Edit Request' ? 'Edit Request:' : 'My Input:'}</strong> {log.text}
                      </div>

                      {/* Display URLs */}
                      {log.urls && log.urls.length > 0 && (
                        <div>
                          <strong>URLs:</strong>
                          <ul className="list-disc ml-5">
                            {log.urls.map((url, urlIndex) => (
                              <li key={urlIndex}>
                                <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                                  {url}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {/* Display Files */}
                      {log.files && log.files.length > 0 && (
                        <div>
                          <strong>Files:</strong>
                          <ul className="list-disc ml-5">
                            {log.files.map((file, fileIndex) => (
                              <li key={fileIndex}><a target='_blank' href={`${process.env.REACT_APP_BACKEND_API_URL}/${file.file}`}>{file.name}</a></li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {/* Display Date */}
                      <div>
                        <strong>Date:</strong> {new Date(log.date).toLocaleString()}
                      </div>
                    </div>
                  ))}




                </div>
              </TabPanel>
            </Tabs>
          ) : (
            <div className='text-2xl font-bold py-2 text-center animate-pulse text-white' style={{ textShadow: '0px 0px 8px rgba(255, 255, 255, 0.5)' }}>Please select a proposal from the sidebar.</div>
          )}
        </div>

      </div>

      {/* Modal for Tasks */}
      {taskModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black-500 bg-opacity-80">
          <div
            ref={modalRef}
            className="bg-white dark:bg-kog-200 shadow-[0px_0px_10px_10px_rgba(0,0,0,0.5)] bg-opacity-50 backdrop-filter backdrop-blur-sm px-4 py-5 rounded min-h-[300px] w-full max-w-[90%] md:max-w-[800px] border border-kog-500 relative mx-4"
          >

            {/* Hourglass Icon */}
            <div className='text-2xl hidden sm:block absolute top-4 right-4	border-kog-300 text-kog-white px-2 py-2 rounded-full mx-1 p-1 shadow-[0px_0px_10px_5px_rgba(0,0,0,0.4)] bg-gradient-to-t from-kog-300 to-yellow-500   ml-3'>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g><path fill="currentColor" d="M7 3H17V7.2L12 12L7 7.2V3Z"><animate id="eosIconsHourglass0" fill="freeze" attributeName="opacity" begin="0;eosIconsHourglass1.end" dur="2s" from="1" to="0" /></path><path fill="currentColor" d="M17 21H7V16.8L12 12L17 16.8V21Z"><animate fill="freeze" attributeName="opacity" begin="0;eosIconsHourglass1.end" dur="2s" from="0" to="1" /></path><path fill="currentColor" d="M6 2V8H6.01L6 8.01L10 12L6 16L6.01 16.01H6V22H18V16.01H17.99L18 16L14 12L18 8.01L17.99 8H18V2H6ZM16 16.5V20H8V16.5L12 12.5L16 16.5ZM12 11.5L8 7.5V4H16V7.5L12 11.5Z" /><animateTransform id="eosIconsHourglass1" attributeName="transform" attributeType="XML" begin="eosIconsHourglass0.end" dur="0.5s" from="0 12 12" to="180 12 12" type="rotate" /></g></svg>
            </div>

            {/* Title */}
            <h2 className="text-kog-100 dark:text-white text-2xl font-bold mb-4">
              {taskTitleMap(proposals.find(p => p.id === selectedProposalId))[selectedTaskKey]}
            </h2>
            {/* Textarea */}
            <textarea
              value={deliverableText[selectedTaskKey] || ''}
              onChange={(e) => handleDeliverableTextChange(selectedTaskKey, e.target.value)}
              className="bg-gray-300 dark:bg-kog-300 text-white  p-4 rounded w-full shadow-[0px_0px_10px_2px_rgba(0,0,0,0.3)]"
              style={{ height: '300px' }}
              placeholder="Please provide updates ONLY for this specific deliverable. (include a link or relevant information, if applicable, that verifies your claim)"
            />
            {/* Save Button */}
            <button
              onClick={() => handleSaveDeliverable(selectedProposalId, selectedTaskKey)}
              className="mt-4 bg-gradient-to-t  from-kog-300 to-kog-900 border border-kog-400 text-white px-4 py-2 rounded-full text-3xl font-bold w-full hover:shadow-[0px_0px_10px_5px_rgba(0,0,0,0.3)] hover:from-kog-900 hover:to-kog-900"
            >
              Save
            </button>
            {/* Close Button */}
            <button
              onClick={handleCloseTaskModal}
              className="m-auto w-full  text-white px-2 py-1 rounded hover:text-kog-900 mt-3"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProposalList;
