
import React, { useRef, useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';

const WalletConnectSignApproveModal = ({
  isOpen,
  connectSignApproveMessage,
  onClose,
}) => {
  const modalRef = useRef(null);
  const [hoveredWallet, setHoveredWallet] = useState(null);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center" style={{zIndex: 100000}}>
      <div
        ref={modalRef}
        className="bg-kog-200 min-w-[450px] bg-opacity-80 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg p-6 account-popup border border-kog-400 border-opacity-50"
      >
        <h2 className="text-lg font-semibold text-kog-900 mb-4">Wallet Connect</h2>
        <div className="flex flex-col space-y-4">
          Please approve the following transaction in your wallet:

          <div className="bg-kog-200 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg p-2 mt-2 account-popup border border-kog-400 border-opacity-50">
              {connectSignApproveMessage}
          </div>

        </div>
        <button
          onClick={onClose}
          className="mt-4 text-gray-200 hover:text-kog-900 text-center w-full"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default WalletConnectSignApproveModal;
