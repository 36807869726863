// AccountSelectionModal.js
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import talismanLogo from '../talisman.svg'; // Talisman logo
import subwalletLogo from '../subwallet.svg'; // SubWallet logo
import { SiPolkadot } from 'react-icons/si'; // Polkadot.js icon
import AccessRequestModal from './AccessRequestModal'; // Import the new modal

const AccountSelectionModal = ({
  isOpen,
  availableAccounts,
  selectedWallet, // Track the selected wallet type
  address,
  onSelectAccount,
  onLogout,
  onClose,
  accessToken,
}) => {
  const [proposalCounts, setProposalCounts] = useState({});
  const [bountyCounts, setBountyCounts] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sortedAccounts, setSortedAccounts] = useState([]);
  const [showAccessRequestModal, setShowAccessRequestModal] = useState(false); // State for Access Request Modal
  const modalRef = useRef(null);

  // Fetch available accounts and proposal counts whenever modal is open
  useEffect(() => {
    const fetchProposalCounts = async () => {
      if (!isOpen) return;

      setLoading(true);
      try {
        const config = {
            headers: {
              Authorization: `${accessToken}`,    
              Account: availableAccounts.filter(x => typeof x!==undefined).shift().address
            }
        };
        
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/data/counts`, config);

        setProposalCounts(response.data.proposal_counts);
        setBountyCounts(response.data.bounties_counts);
   
      } catch (error) {        
        setError('Failed to fetch proposals (0xA)');
      } finally {
        setLoading(false);
      }
    };

    if (availableAccounts.length > 0) {
      fetchProposalCounts();
    }
  }, [isOpen, availableAccounts]);

  // Close modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(); // Close the current modal
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    if (availableAccounts.length > 0) {
      // Sort accounts based on selected account for the specific wallet
      const initialSort = availableAccounts.sort((a, b) => {
        if (a.address === address && a.wallet === selectedWallet) return -1;
        return 1;
      });
      setSortedAccounts(initialSort);
    }
  }, [availableAccounts, selectedWallet, address]);

  // Handle account selection and move the selected account to the top
  const handleAccountSelection = (account) => {
    onSelectAccount(account);

    // Filter out duplicates: remove the selected account for the same address but different wallet
    const updatedAccounts = sortedAccounts.filter(
      (acc) => !(acc.address === account.address && acc.meta.source === account.meta.source)
    );

    setSortedAccounts([account, ...updatedAccounts]);
  };

  // Function to return the correct icon based on the wallet source
  const getWalletIcon = (source) => {
    switch (source) {
      case 'talisman':
        return <img src={talismanLogo} alt="Talisman" className="w-4 h-4" />;
      case 'subwallet-js':
        return <img src={subwalletLogo} alt="SubWallet" className="w-4 h-4" />;
      case 'polkadot-js':
        return <SiPolkadot className="w-4 h-4 text-white" />;
      default:
        return <span>Unknown Wallet</span>;
    }
  };

  // Show the modal or null if not open
  if (!isOpen && !showAccessRequestModal) return null;

  return (
    <>
      {/* Account Selection Modal */}
      {isOpen && !showAccessRequestModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div
            ref={modalRef}
            className="bg-kog-200 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-lg  w-full max-w-[550px] shadow-[0px_0px_15px_5px_rgba(0,0,0,0.3)] p-6 account-popup border border-kog-400 border-opacity-50"
          >
            <h3 className="text-lg font-semibold text-kog-900 mb-4">Available Accounts</h3>
            {loading ? (
              <p>Loading proposal counts...</p>
            ) : error ? (
              <p className="text-red-600">{error}</p>
            ) : (
              <div className="flex flex-col space-y-4">
                {sortedAccounts.map((account) => {
                  const key = `${account.address}-${account.meta.source}`; // Use meta.source for wallet identification
                  const proposal = proposalCounts[key] || null;
                  const bounty = bountyCounts[key] || null;
                  
                  const proposalCount = (proposal && proposal.count ? proposal.count : 0) || 0;
                  const bountyCount = (bounty && bounty.count ? bounty.count : 0) || 0;
                  let isNonClickable = (proposalCount === 0 && bountyCount === 0);
                  if (proposal && proposal.admin) {
                    isNonClickable = false; 
                  }
                  return (
                    <div
                    key={key}
                    className={`relative flex justify-between items-center pl-1 pr-4 py-1 sm:py-2 border border-kog-400 rounded ${
                      account.address === address && account.meta.source === selectedWallet
                        ? 'bg-kog-400 text-white shadow-[0px_0px_5px_2px_rgba(0,0,0,0.3)]'
                        : ''
                    } ${isNonClickable ? 'cursor-not-allowed opacity-50' : 'hover:bg-kog-400 cursor-pointer'}`}
                    onClick={!isNonClickable ? () => handleAccountSelection(account) : null}
                  >
                    <div className="flex items-center space-x-1 flex-shrink">
                      <span className="inline-block border p-2 rounded-full border-kog-600 border-opacity-40">
                        {getWalletIcon(account.meta.source)} {/* Wallet icon */}
                      </span>
                      <div className="block font-bold text-lg text-white">
                        {account.meta.name || account.address.slice(0, 6)}
                        {/* Truncated address */}
                        <span className="block text-xs text-white font-thin truncate max-w-[150px] sm:max-w-[200px] md:max-w-[350px]">
                          {account.address}
                        </span>
                      </div>
                    </div>
                    <div className="flex-shrink-0 text-right">
                      <span className="block font-semibold text-lg text-white">{proposalCount}</span>
                      <span className="block text-xs text-white">Proposals</span>
                    </div>
                  </div>
                  

                  );
                })}
              </div>
            )}

            {address && (
              <div className="border-t mt-4 pt-4">
                <button
                  onClick={() => {
                    onLogout();
                    onClose();
                  }}
                  className="w-full bg-kog-300 text-white py-2 rounded-lg hover:bg-kog-400 border border-kog-400 border-opacity-50"
                >
                  Logout
                </button>
              </div>
            )}
            <button onClick={onClose} className="w-full mt-4 text-gray-300 hover:text-kog-900">
              Close
            </button>

            <button
              onClick={() => {
                onClose(); // Close current modal
                setShowAccessRequestModal(true); // Show the new modal
              }}
              className="w-full text-lg mt-4 text-white hover:text-kog-900 border-t border-kog-400 border-opacity-50 pt-4 font-bold"
            >
              Request access with a <span className='text-kog-900'>different</span> address
            </button>
          </div>
        </div>
      )}

      {/* Render the Access Request Modal */}
      {showAccessRequestModal && (
        <AccessRequestModal
          isOpen={showAccessRequestModal}
          onClose={() => setShowAccessRequestModal(false)} // Close the AccessRequestModal
          accessToken={accessToken}
          accounts={availableAccounts}
        />
      )}
    </>
  );
};

export default AccountSelectionModal;
