// WalletSelectionModal.js
import React, { useRef, useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { SiPolkadot } from 'react-icons/si';
import novaLogo from '../nova.png';
import talismanLogo from '../talisman.svg';
import subwalletLogo from '../subwallet.svg';
import walletConnectLogo from '../walletconnect.svg';
import { walletMapping } from '../walletMapping'; // Adjust the path if necessary

const WalletSelectionModal = ({
  isOpen,
  onClose,
  handleWalletSelection,
  onWalletConnect,
}) => {
  const modalRef = useRef(null);
  const [hoveredWallet, setHoveredWallet] = useState(null);
  const [connectingWallet, setConnectingWallet] = useState(null);

  const [installedWallets, setInstalledWallets] = useState({
    nova: false,
    talisman: false,
    subwallet: false,
    polkadotjs: false,
  });

  const [connectedWallets, setConnectedWallets] = useState([]);

  useEffect(() => {
    if (isOpen) {
      const checkWalletsInstalledAndConnected = async () => {
        const injectedWeb3 = window.injectedWeb3 || {};
        const walletsStatus = {
          nova: {
            installed: !!window.walletExtension && !!window.walletExtension.isNovaWallet,
            connected: false,
          },
          talisman: {
            installed: !!injectedWeb3.talisman,
            connected: false,
          },
          subwallet: {
            installed: !!injectedWeb3['subwallet-js'],
            connected: false,
          },
          polkadotjs: {
            installed: !!injectedWeb3['polkadot-js'],
            connected: false,
          },
        };

        setInstalledWallets({
          nova: walletsStatus.nova.installed,
          talisman: walletsStatus.talisman.installed,
          subwallet: walletsStatus.subwallet.installed,
          polkadotjs: walletsStatus.polkadotjs.installed,
        });

        // Check if wallets are connected without triggering pop-ups
        const connectedWalletsArray = [];

        // Check Talisman
        if (walletsStatus.talisman.installed) {
          const talismanExtension = injectedWeb3.talisman;
          if (talismanExtension.isEnabled) {
            const isEnabled = await talismanExtension.isEnabled();
            if (isEnabled) {
              connectedWalletsArray.push('talisman');
            }
          }
        }

        // Check SubWallet
        if (walletsStatus.subwallet.installed) {
          const subwalletExtension = injectedWeb3['subwallet-js'];
          if (subwalletExtension.isEnabled) {
            const isEnabled = await subwalletExtension.isEnabled();
            if (isEnabled) {
              connectedWalletsArray.push('subwallet');
            }
          }
        }

        // Check Polkadot.js
        if (walletsStatus.polkadotjs.installed) {
          const polkadotJsExtension = injectedWeb3['polkadot-js'];
          if (polkadotJsExtension.isEnabled) {
            const isEnabled = await polkadotJsExtension.isEnabled();
            if (isEnabled) {
              connectedWalletsArray.push('polkadot-js');
            }
          }
        }

        // Nova Wallet handling (may not support isEnabled)
        // We'll assume we cannot check Nova Wallet's connection status without triggering a pop-up

        setConnectedWallets(connectedWalletsArray);
      };
      setConnectingWallet(null)
      checkWalletsInstalledAndConnected();
    }
  }, [isOpen]);

  useEffect(() => {
    if (connectingWallet === 'walletConnect') {
      onWalletConnect();
    }
  }, [connectingWallet]);
  
  const handleWalletConnect = async (walletType) => {
    setConnectingWallet('walletConnect');
  };

  const handleOtherWalletConnection = async (walletType) => {
    setConnectingWallet(walletType);

    try {
      await handleWalletSelection(walletType);
    } catch (error) {
      console.error('Error connecting to wallet:', error);
    } finally {
      setConnectingWallet(null);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) return null;

  // Helper function to determine overlay message
  const getOverlayMessage = (walletName) => {
    if (walletName === 'walletConnect') {
      return 'Connect';
    } else if (!installedWallets[walletName]) {
      return 'Not Installed';
    } else if (!connectedWallets.includes(walletName)) {
      return 'Connect';
    } else {
      return null;
    }
  };

  const wallets = Object.keys(walletMapping).map((walletType) => {

    const walletInfo = walletMapping[walletType];
    return {
      name: walletType,
      displayName: walletInfo.displayName,
      logo: walletInfo.logo,
    };
  });

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div
        ref={modalRef}
        className="bg-kog-200 min-w-[450px] bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg p-6 account-popup border border-kog-400 border-opacity-50"
      >
        <h2 className="text-lg font-semibold text-kog-900 mb-4">Choose your wallet</h2>
        <div className="flex flex-col space-y-4">
          {wallets.map((wallet) => {
            const isInstalled = installedWallets[wallet.name];
            const overlayMessage = getOverlayMessage(wallet.name);

            return (
              <div
                key={wallet.name}
                onMouseEnter={() => setHoveredWallet(wallet.name)}
                onMouseLeave={() => setHoveredWallet(null)}
                className={`relative flex items-center px-2 py-3 rounded shadow-md mx-1 border border-kog-500 hover:bg-kog-500 ${
                  isInstalled ? 'cursor-pointer' : 'cursor-not-allowed'
                }`}
                onClick={() => isInstalled && handleOtherWalletConnection(wallet.name)}
              >
                {wallet.logo ? (
                  <img src={wallet.logo} alt={`${wallet.displayName} Logo`} className="w-6 h-6" />
                ) : (
                  <SiPolkadot className="w-6 h-6" />
                )}
                <span className="mx-auto text-white text-lg">
                  {connectingWallet === wallet.name ? 'Connecting...' : wallet.displayName}
                </span>
                <FaArrowRight className="w-4 h-4" />
                {/* Overlay Conditions */}
                {hoveredWallet === wallet.name && overlayMessage && (
                  <div className="absolute inset-0 bg-kog-400 text-lg bg-opacity-100 flex items-center justify-center text-white text-md rounded p-2 shadow-lg">
                    {overlayMessage}
                  </div>
                )}
              </div>
            );
          })}

          {/* WalletConnect Button */}
          <div
            onMouseEnter={() => setHoveredWallet('walletConnect')}
            onMouseLeave={() => setHoveredWallet(null)}
            onClick={() => handleWalletConnect()}
            className={`relative flex items-center px-2 py-3 rounded mx-1 shadow-md border border-kog-500 hover:bg-kog-500 cursor-pointer`}
          >
            <img src={walletConnectLogo} alt="WalletConnect Logo" className="w-8 h-7" />
            <span className="mx-auto text-white text-lg">
              {connectingWallet === 'walletConnect' ? 'Connecting...' : 'WalletConnect'}
            </span>
            <FaArrowRight className="w-4 h-4" />
            {/* Overlay Conditions */}
            {hoveredWallet === 'walletConnect' && (
              <div className="absolute inset-0 bg-kog-400 text-lg bg-opacity-100 flex items-center justify-center text-white text-md rounded p-2 shadow-lg">
                Connect
              </div>
            )}
          </div>
        </div>
        <button
          onClick={onClose}
          className="mt-4 text-gray-200 hover:text-kog-900 text-center w-full"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default WalletSelectionModal;
