
import React, { useRef, useEffect, useState } from 'react';
import AccessRequestModal from './AccessRequestModal'; // Import the new modal
import { FaArrowRight } from 'react-icons/fa';

const AccountNotFound = ({
  isOpen,
  onClose,
}) => {
  const modalRef = useRef(null);
  const [hoveredWallet, setHoveredWallet] = useState(null);
  const [showAccessRequestModal, setShowAccessRequestModal] = useState(false); // State for Access Request Modal


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen && !showAccessRequestModal) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center" style={{zIndex: 100000}}>
      <div
        ref={modalRef}
        className="bg-kog-200 min-w-[450px] bg-opacity-80 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg p-6 account-popup border border-kog-400 border-opacity-50"
      >
        <h2 className="text-lg font-semibold text-kog-900 mb-4">OG Report</h2>
        <div className="flex flex-col space-y-4">
          There are no records for the selected account in our database.

        </div>
        <button
          onClick={onClose}
          className="mt-4 text-gray-200 hover:text-kog-900 text-center w-full"
        >
          Close
        </button>
        
        <button
              onClick={() => {
                setShowAccessRequestModal(true); // Show the new modal
                onClose(); // Close current modal
              }}
              className="w-full text-lg mt-4 text-white hover:text-kog-900 border-t border-kog-400 border-opacity-50 pt-4 font-bold"
            >
              Request access with a <span className='text-kog-900'>different</span> address
            </button>
      </div>
      

      {/* Render the Access Request Modal */}
      {showAccessRequestModal && (
        <AccessRequestModal
          isOpen={showAccessRequestModal}
          onClose={() => setShowAccessRequestModal(false)} // Close the AccessRequestModal
        />
      )}
    </div>
  );
};

export default AccountNotFound;
