import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ProposalStats = ({ address, accessToken }) => {
  const [stats, setStats] = useState({
    totalProposals: 0,
    totalDotRequested: 0,
    inProgressProposals: 0,
    deliveredProposals: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchStats = async () => {
        try {const config = {
            headers: {
              Authorization: `${accessToken}`,
              Account: address
            }
        };
        
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/data`, config);
        //const response = await axios.get(`${process.env.REACT_APP_API_URL}`); // Adjust URL if needed
        // Filter proposals where benAdd or proposerAdd matches the connected address
        const filteredProposals = response.data.filter(proposal => 
          proposal.benAdd === address || proposal.proposerAdd === address
        );



        const totalProposals = filteredProposals.length;
        
        // Helper function to parse and convert reqDot values
        const parseDotValue = (value) => {
          if (typeof value === 'string') {
            return parseFloat(value.replace(/,/g, ''));
          }
          return 0;
        };

        const totalDotRequested = filteredProposals.reduce((total, proposal) => {
          return total + parseDotValue(proposal.reqDot);
        }, 0);

        const inProgressProposals = filteredProposals.filter(proposal => proposal.status === 'InProgress').length;
        const deliveredProposals = filteredProposals.filter(proposal => proposal.status === 'Delivered').length;

        setStats({
          totalProposals,
          totalDotRequested,
          inProgressProposals,
          deliveredProposals,
        });
      } catch (error) {
        console.error('Failed to fetch stats:', error);
        setError('Failed to fetch stats');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [address]);

  if (loading) {
    return <div>Loading stats...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="w-full p-4 ">
      <div className="container mx-auto w-full">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="">
            <h3 className="text-lg sm:text-xl font-bold mb-2 text-kog-100 dark:text-pink-400 text-center ">Total Proposals</h3>
            <p className="text-3xl sm:text-5xl font-bold px-4 py-3 text-center bg-gradient-to-b from-kog-900 to-white dark:from-kog-300 dark:to-kog-50 rounded-full border border-kog-100 dark:border-kog-400 [text-shadow:_0_5px_10px_rgb(0_0_0_/_60%)] text-kog-100  dark:text-white shadow-[0px_0px_10px_1px_rgba(239,26,229,0.5)]">{stats.totalProposals}</p>
          </div>
          <div className="">
            <h3 className="text-lg sm:text-xl font-bold mb-2 text-kog-100 dark:text-pink-400 text-center">Total DOT Requested</h3>
            <p className="text-3xl sm:text-5xl font-bold px-4 py-3 text-center bg-gradient-to-b from-kog-900 to-white dark:from-kog-300 dark:to-kog-50 rounded-full border border-kog-100 dark:border-kog-400 [text-shadow:_0_5px_10px_rgb(0_0_0_/_60%)] text-kog-100  dark:text-white shadow-[0px_0px_10px_1px_rgba(239,26,229,0.5)]">{stats.totalDotRequested.toLocaleString()}</p> {/* Format with commas */}
          </div>
          <div className="">
            <h3 className="text-lg sm:text-xl font-bold mb-2 text-kog-100 dark:text-pink-400 text-center">In Progress</h3>
            <p className="text-3xl sm:text-5xl font-bold px-4 py-3 text-center bg-gradient-to-b from-kog-900 to-white dark:from-kog-300 dark:to-kog-50 rounded-full border border-kog-100 dark:border-kog-400 [text-shadow:_0_5px_10px_rgb(0_0_0_/_60%)] text-kog-100  dark:text-white shadow-[0px_0px_10px_1px_rgba(239,26,229,0.5)]">{stats.inProgressProposals}</p>
          </div>
          <div className="">
            <h3 className="text-lg sm:text-xl font-bold mb-2 text-kog-100 dark:text-pink-400 text-center">Delivered</h3>
            <p className="text-3xl sm:text-5xl font-bold px-4 py-3 text-center bg-gradient-to-b from-kog-900 to-white dark:from-kog-300 dark:to-kog-50 rounded-full border border-kog-100 dark:border-kog-400 [text-shadow:_0_5px_10px_rgb(0_0_0_/_60%)] text-kog-100  dark:text-white shadow-[0px_0px_10px_1px_rgba(239,26,229,0.5)]">{stats.deliveredProposals}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProposalStats;
