import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; // Generate unique IDs

const AccessRequestModal = ({ isOpen, onClose, accessToken, accounts }) => {
  const [reqRef, setReqRef] = useState('');
  const [address, setAddress] = useState('');
  const [handle, setHandle] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const modalRef = useRef(null); // Ref for the modal container

  // Handle submit only if all fields are filled
  const handleSubmit = async () => {
    if (!reqRef || !address || !handle) {
      setError('All fields are mandatory.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Generate a new entry with unique id, address, handle, and date
      const newAccessEntry = {
        id: uuidv4(), // Unique ID
        reqRef,
        address,
        handle,
        date: new Date().toISOString(), // Add the current date and time,
        accounts: accounts ? accounts.map( a=> a.address) : {}
      };

    
      await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/access-requests`, newAccessEntry);
      // Use POST to add the new entry to the access array
      //await axios.post(`${process.env.REACT_APP_API_URL}`, newAccessEntry); // POST request to add entry

      // Close the modal after successful submission
      onClose();
    } catch (err) {
      console.error(err);
      setError('Failed to submit access request.');
    } finally {
      setLoading(false);
    }
  };

  // Close modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 max-w-[550px] m-auto">
      <div
        ref={modalRef} // Attach ref to the modal container
        className="bg-kog-200 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-lg mx-4 shadow-[0px_0px_15px_5px_rgba(0,0,0,0.3)] p-6 account-popup border border-kog-400 border-opacity-50"
      >
        <h3 className="text-lg font-semibold text-white mb-4">Request Access</h3>

        {error && <p className="text-red-600 mb-2">{error}</p>}

        <input
          type="text"
          value={reqRef}
          onChange={(e) => setReqRef(e.target.value)}
          className="w-full p-2  shadow-[0px_0px_15px_5px_rgba(0,0,0,0.3)] bg-kog-300 rounded mb-4"
          placeholder="Provide the #REF you want to access"
        />

        <input
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="w-full p-2  shadow-[0px_0px_15px_5px_rgba(0,0,0,0.3)] bg-kog-300 rounded mb-4"
          placeholder="Provide your Polkadot address"
        />

        <input
          type="text"
          value={handle}
          onChange={(e) => setHandle(e.target.value)}
          className="w-full bg-kog-300 p-2 shadow-[0px_0px_15px_5px_rgba(0,0,0,0.3)] rounded mb-4"
          placeholder="Provide a point of contact"
        />

        <button
          onClick={handleSubmit}
          disabled={loading}
          className="bg-gradient-to-t from-kog-300 to-kog-900 border border-kog-400 rounded-full text-white py-2 px-4  w-full"
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>

        <button
          onClick={onClose}
          className="w-full mt-4 text-gray-200 hover:text-kog-900"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AccessRequestModal;
