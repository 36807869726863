import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const AdminDeliverables = ({ address, accessToken }) => {
  const [proposals, setProposals] = useState([]);
  const [filteredProposals, setFilteredProposals] = useState([]);
  const [bounties, setBounties] = useState([]);
  const [accessEntries, setAccessEntries] = useState([]);
  const [searchRef, setSearchRef] = useState('');
  const [latestRef, setLatestRef] = useState('');
  const [showTodaySubmissionsModal, setShowTodaySubmissionsModal] = useState(false);
  const [todaySubmissions, setTodaySubmissions] = useState([]);
  const [totals, setTotals] = useState({
    totalReports: 0,
    totalDeliverables: 0,
    totalEdits: 0,
    todaysSubmissions: 0,
  });

  const modalRef = useRef(null);

  // Close modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowTodaySubmissionsModal(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Fetch proposals, access data, and bounties from backend (db.json)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
            headers: {
              Authorization: `${accessToken}`,
              Account: address
            }
        };
        
        const proposalsResponse = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/data`, config);

        setProposals(proposalsResponse.data);
        setFilteredProposals(proposalsResponse.data);
        
        
        const accessResponse = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/access-requests`, config);
        setAccessEntries(accessResponse.data.sort((a, b) => new Date(b.date) - new Date(a.date))); // Sort access entries by date (newest first)

        const bountiesResponse = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/bounties`, config);
        setBounties(bountiesResponse.data);

        // Calculate totals and latest submission reference
        calculateTotals(proposalsResponse.data);
        findLatestSubmission(proposalsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Filter proposals by refNum when searchRef changes
  useEffect(() => {
    if (searchRef.trim() === '') {
      setFilteredProposals(proposals);
    } else {
      const filtered = proposals.filter((proposal) =>
        proposal.refNum.toLowerCase().includes(searchRef.toLowerCase())
      );
      setFilteredProposals(filtered);
    }
  }, [searchRef, proposals]);

  // Calculate totals for reports, deliverables, edits, and today's submissions
  const calculateTotals = (proposals) => {
    let totalReports = 0;
    let totalDeliverables = 0;
    let totalEdits = 0;
    let todaysSubmissions = [];
    const today = new Date().toLocaleDateString();

    proposals.forEach((proposal) => {
      if (proposal.reports) {
        totalReports += Object.values(proposal.reports).flat().length;
        todaysSubmissions.push(
          ...Object.values(proposal.reports).flat().filter((entry) => new Date(entry.date).toLocaleDateString() === today)
        );
      }
      if (proposal.deliverables) {
        totalDeliverables += Object.values(proposal.deliverables).flat().length;
        todaysSubmissions.push(
          ...Object.values(proposal.deliverables).flat().filter((entry) => new Date(entry.date).toLocaleDateString() === today)
        );
      }
      if (proposal.edits) {
        totalEdits += Object.values(proposal.edits).flat().length;
        todaysSubmissions.push(
          ...Object.values(proposal.edits).flat().filter((entry) => new Date(entry.date).toLocaleDateString() === today)
        );
      }
    });

    setTotals({ totalReports, totalDeliverables, totalEdits, todaysSubmissions: todaysSubmissions.length });
    setTodaySubmissions(todaysSubmissions);
  };

  // Find the refNum with the latest submission (report, task, or edit)
  const findLatestSubmission = (proposals) => {
    let latestDate = 0;
    let latestRef = '';

    proposals.forEach((proposal) => {
      const allEntries = [
        ...Object.values(proposal.reports || {}).flat(),
        ...Object.values(proposal.deliverables || {}).flat(),
        ...Object.values(proposal.edits || {}).flat(),
      ];

      allEntries.forEach((entry) => {
        const entryDate = new Date(entry.date).getTime();
        if (entryDate > latestDate) {
          latestDate = entryDate;
          latestRef = proposal.refNum;
        }
      });
    });

    setLatestRef(latestRef);
  };

  // Helper function to map tasks
  const taskTitleMap = (proposal) => {
    const titles = {};
    Object.keys(proposal)
      .filter((key) => key.startsWith('task') && proposal[key])
      .forEach((taskKey) => {
        titles[taskKey] = proposal[taskKey];
      });
    return titles;
  };

  // Group by refNum and date and sort deliverables, reports, and edits by the latest date
  const groupByRefNumAndDate = (proposals) => {
    const grouped = {};

    proposals.forEach((proposal) => {
      const { refNum, deliverables, reports, edits } = proposal;
      const allEntries = [
        ...(deliverables
          ? Object.entries(deliverables).flatMap(([taskKey, entries]) =>
              entries.map((entry) => ({
                ...entry,
                task: taskTitleMap(proposal)[taskKey],
                type: 'Deliverable',
                submittedBy: entry.submittedBy || 'Unknown',
              }))
            )
          : []),
        ...(reports
          ? Object.entries(reports).flatMap(([reportTitle, entries]) =>
              entries.map((entry) => ({
                ...entry,
                task: reportTitle,
                type: 'Report',
                submittedBy: entry.submittedBy || 'Unknown',
              }))
            )
          : []),
        ...(edits
          ? Object.entries(edits).flatMap(([taskKey, entries]) =>
              entries.map((entry) => ({
                ...entry,
                task: taskTitleMap(proposal)[taskKey],
                type: 'Edit Request',
                submittedBy: entry.submittedBy || 'Unknown',
              }))
            )
          : []),
      ];

      allEntries.forEach((entry) => {
        const date = new Date(entry.date).toLocaleDateString(); // Format date

        if (!grouped[refNum]) grouped[refNum] = {};
        if (!grouped[refNum][date]) grouped[refNum][date] = [];

        grouped[refNum][date].push(entry);
      });
    });

    return grouped;
  };

  const groupedProposals = groupByRefNumAndDate(filteredProposals);

  // Group bounties' reports by bNum and date, sorted from newest to oldest
  const groupBountiesByDate = (bounties) => {
    const grouped = {};

    bounties.forEach((bounty) => {
      if (bounty.reports) {
        Object.entries(bounty.reports).forEach(([reportTitle, entries]) => {
          entries.forEach((entry) => {
            const date = new Date(entry.date).toLocaleDateString();
            if (!grouped[bounty.bNum]) grouped[bounty.bNum] = {};
            if (!grouped[bounty.bNum][date]) grouped[bounty.bNum][date] = [];

            grouped[bounty.bNum][date].push({
              ...entry,
              task: entry.title,
              type: 'Report',
              submittedBy: entry.submittedBy || 'Unknown',
            });
          });
        });
      }
    });

    return grouped;
  };

  const groupedBounties = groupBountiesByDate(bounties);

  // Sort refs by the latest submission date (newest to oldest)
  const sortedRefs = Object.keys(groupedProposals).sort((a, b) => {
    const latestA = Math.max(...Object.keys(groupedProposals[a]).map(date => new Date(Math.max(...groupedProposals[a][date].map(e => new Date(e.date))))));
    const latestB = Math.max(...Object.keys(groupedProposals[b]).map(date => new Date(Math.max(...groupedProposals[b][date].map(e => new Date(e.date))))));
    return latestB - latestA;
  });

  const sortedBountyRefs = Object.keys(groupedBounties).sort((a, b) => {
    const latestA = Math.max(...Object.keys(groupedBounties[a]).map(date => new Date(Math.max(...groupedBounties[a][date].map(e => new Date(e.date))))));
    const latestB = Math.max(...Object.keys(groupedBounties[b]).map(date => new Date(Math.max(...groupedBounties[b][date].map(e => new Date(e.date))))));
    return latestB - latestA;
  });

  // Modal to display today's submissions
  const renderTodaySubmissionsModal = () => {
    const today = new Date().toLocaleDateString(); // Get today's date in a comparable format
    const todaySubmissions = [];
  
    // Iterate through proposals and gather today's submissions
    proposals.forEach((proposal) => {
      const refNum = proposal.refNum;
  
      // Collect today's reports
      if (proposal.reports) {
        Object.entries(proposal.reports).forEach(([reportTitle, entries]) => {
          entries.forEach((entry) => {
            if (new Date(entry.date).toLocaleDateString() === today) {
              todaySubmissions.push({
                refNum,
                type: 'Report',
                text: entry.text,
                date: entry.date,
                urls: entry.urls || [],
                submittedBy: entry.submittedBy || 'Unknown',
              });
            }
          });
        });
      }
  
      // Collect today's deliverables
      if (proposal.deliverables) {
        Object.entries(proposal.deliverables).forEach(([taskKey, entries]) => {
          entries.forEach((entry) => {
            if (new Date(entry.date).toLocaleDateString() === today) {
              todaySubmissions.push({
                refNum,
                type: 'Deliverable',
                text: entry.text,
                date: entry.date,
                urls: entry.urls || [],
                submittedBy: entry.submittedBy || 'Unknown',
              });
            }
          });
        });
      }
  
      // Collect today's edit requests
      if (proposal.edits) {
        Object.entries(proposal.edits).forEach(([taskKey, entries]) => {
          entries.forEach((entry) => {
            if (new Date(entry.date).toLocaleDateString() === today) {
              todaySubmissions.push({
                refNum,
                type: 'Edit Request',
                text: entry.text,
                date: entry.date,
                urls: entry.urls || [],
                submittedBy: entry.submittedBy || 'Unknown',
              });
            }
          });
        });
      }
    });
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div ref={modalRef} className="bg-kog-100 p-6 rounded-lg shadow-lg w-3/4 max-h-[80vh] overflow-y-auto">
          <h2 className="text-xl font-bold mb-4">Today's Submissions (Detailed)</h2>
          {todaySubmissions.length === 0 ? (
            <p>No submissions today.</p>
          ) : (
            <ul>
              {todaySubmissions
                .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date (newest first)
                .map((submission, index) => (
                  <li key={index} className="mb-4 p-4 bg-kog-300 rounded shadow-md">
                    <div className="mb-2">
                      <strong>RefNum:</strong> {submission.refNum || 'Unknown'}
                    </div>
                    <div className="mb-2">
                      <strong>Type:</strong> {submission.type || 'Unknown'}
                    </div>
                    <div className="mb-2">
                      <strong>Details:</strong> {submission.text || 'No details'}
                    </div>
                    <div className="mb-2">
                      <strong>Submitted by:</strong> {submission.submittedBy}
                    </div>
                    {/* URLs if available */}
                    {submission.urls && submission.urls.length > 0 && (
                      <div className="mb-2">
                        <strong>URLs:</strong>
                        <ul className="list-disc ml-5">
                          {submission.urls.map((url, urlIndex) => (
                            <li key={urlIndex}>
                              <a
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 underline"
                              >
                                {url}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <div>
                      <strong>Date:</strong> {new Date(submission.date).toLocaleString()}
                    </div>
                  </li>
                ))}
            </ul>
          )}
          <button
            onClick={() => setShowTodaySubmissionsModal(false)}
            className="mt-4 bg-kog-500 text-white px-4 py-2 rounded-full"
          >
            Close
          </button>
          
        </div>
      </div>
    );
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-16"></h1>

      {showTodaySubmissionsModal && renderTodaySubmissionsModal()}

      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
        <div className="p-4 bg-kog-300 text-white rounded shadow-md text-center">
          <h2 className="text-xl font-semibold">Total Reports</h2>
          <p className="text-3xl">{totals.totalReports}</p>
        </div>
        <div className="p-4 bg-kog-300 text-white rounded shadow-md text-center">
          <h2 className="text-xl font-semibold">Total Deliverables</h2>
          <p className="text-3xl">{totals.totalDeliverables}</p>
        </div>
        <div className="p-4 bg-kog-300 text-white rounded shadow-md text-center">
          <h2 className="text-xl font-semibold">Total Edits</h2>
          <p className="text-3xl">{totals.totalEdits}</p>
        </div>
        <div className="p-4 bg-kog-300 text-white rounded shadow-md text-center">
          <button
            onClick={() => setShowTodaySubmissionsModal(true)}
            className="bg-gradient-to-t from-kog-700 to-kog-900 text-white px-4 py-2 rounded-full mb-4"
          >
            Today's Submissions
          </button>
          <p className="text-3xl">{totals.todaysSubmissions}</p>
        </div>
      </div>

      <Tabs>
        <TabList className="tabs-container flex space-x-1 sm:space-x-4 mb-4">
          <Tab
            selectedClassName="bg-gradient-to-t from-kog-700 to-kog-900 shadow-kog-900 text-white"
            className="tab-report px-4 py-2 rounded-full text-kog-100 dark:text-white dark:bg-kog-200 shadow-[0px_0px_5px_2px_rgba(0,0,0,0.3)] dark:shadow-[0px_0px_10px_1px_rgba(0,0,0,0.7)] text-black transition-all cursor-pointer border border-kog-800 dark:border-kog-900 dark:border-opacity-30"
          >
            Proposals
          </Tab>
          <Tab
            selectedClassName="bg-gradient-to-t from-kog-700 to-kog-900 shadow-kog-900 text-white"
            className="tab-report px-4 py-2 rounded-full text-kog-100 dark:text-white dark:bg-kog-200 shadow-[0px_0px_5px_2px_rgba(0,0,0,0.3)] dark:shadow-[0px_0px_10px_1px_rgba(0,0,0,0.7)] text-black transition-all cursor-pointer border border-kog-800 dark:border-kog-900 dark:border-opacity-30"
          >
            Bounties
          </Tab>
          <Tab
            selectedClassName="bg-gradient-to-t from-kog-700 to-kog-900 shadow-kog-900 text-white"
            className="tab-report px-4 py-2 rounded-full text-kog-100 dark:text-white dark:bg-kog-200 shadow-[0px_0px_5px_2px_rgba(0,0,0,0.3)] dark:shadow-[0px_0px_10px_1px_rgba(0,0,0,0.7)] text-black transition-all cursor-pointer border border-kog-800 dark:border-kog-900 dark:border-opacity-30"
          >
            Access
            
          </Tab>
        </TabList>

        <TabPanel>
          <input
            type="text"
            value={searchRef}
            onChange={(e) => setSearchRef(e.target.value)}
            placeholder="Search by Ref Number"
            className="p-2 border border-gray-300 bg-kog-300 rounded w-full mb-4"
          />
          {sortedRefs.length === 0 ? (
            <p>No deliverables found.</p>
          ) : (
            sortedRefs.map((refNum) => (
              <div key={refNum} className="p-4 mb-6 border border-gray-300 rounded shadow-lg bg-kog-100">
                <h2 className="text-3xl font-semibold mb-4">#{refNum}</h2>
                {Object.keys(groupedProposals[refNum])
                  .sort((a, b) => new Date(b) - new Date(a)) // Sort dates descending
                  .map((date) => (
                    <div key={date} className="mb-4">
                      <h3 className="text-lg font-medium mb-2">{date}</h3>
                      <ul className="ml-4">
                        {groupedProposals[refNum][date]
                          .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by newest first
                          .map((entry, index) => (
                            <li key={index} className="relative p-2 bg-kog-300 rounded mb-2">
                              <div>
                                <strong>{entry.type === 'Report' ? 'Title:' : entry.type === 'Edit Request' ? 'Edit Task:' : 'Task:'}</strong>{' '}
                                {entry.task || 'N/A'}
                              </div>
                              <div>
                                <strong>{entry.type === 'Report' ? 'Details:' : entry.type === 'Edit Request' ? 'Edit Request:' : 'Submission:'}</strong>{' '}
                                {entry.text}
                              </div>
                              <div>
                                <strong>Submitted by:</strong> {entry.submittedBy}
                              </div>

                              <span
                                className={`absolute top-2 right-2 px-3 py-1 rounded-full text-white text-sm font-bold ${
                                  entry.type === 'Report'
                                    ? 'bg-gradient-to-t from-kog-300 to-blue-500'
                                    : entry.type === 'Edit Request'
                                    ? 'bg-gradient-to-t from-orange-900 to-orange-500'
                                    : 'bg-gradient-to-t from-kog-300 to-green-500'
                                }`}
                              >
                                {entry.type}
                              </span>

                              {entry.urls && entry.urls.length > 0 && (
                                <div>
                                  <strong>URLs:</strong>
                                  <ul className="list-disc ml-5">
                                    {entry.urls.map((url, urlIndex) => (
                                      <li key={urlIndex}>
                                        <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                                          {url}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}


                              {entry.files && entry.files.length > 0 && (
                                <div>
                                  <strong>Files:</strong>
                                  <ul className="list-disc ml-5">
                                    {entry.files.map((file, fileIndex) => (
                                      <li key={fileIndex}>
                                        <li key={fileIndex}><a target='_blank' href={`${process.env.REACT_APP_BACKEND_API_URL}/${file.file}`}>{file.name}</a></li>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}

                              <div className="text-white text-sm">
                                <strong>Date:</strong> {new Date(entry.date).toLocaleString()}
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  ))}
              </div>
            ))
          )}
        </TabPanel>

        {/* Bounties Tab */}
        <TabPanel>
          <h2 className="text-2xl font-bold mb-4">Bounties Reports</h2>
          {sortedBountyRefs.length === 0 ? (
            <p>No reports found for bounties.</p>
          ) : (
            sortedBountyRefs.map((bNum) => (
              <div key={bNum} className="p-4 mb-6 border border-gray-300 rounded shadow-lg bg-kog-100">
                <h2 className="text-3xl font-semibold mb-4">Bounty #{bNum}</h2>
                {Object.keys(groupedBounties[bNum])
                  .sort((a, b) => new Date(b) - new Date(a)) // Sort dates descending
                  .map((date) => (
                    <div key={date} className="mb-4">
                      <h3 className="text-lg font-medium mb-2">{date}</h3>
                      <ul className="ml-4">
                        {groupedBounties[bNum][date]
                          .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by newest first
                          .map((entry, index) => (
                            <li key={index} className="relative p-2 bg-kog-300 rounded mb-2">
                              <div>
                                <strong>Title:</strong> {entry.task || 'N/A'}
                              </div>
                              <div>
                                <strong>Details:</strong> {entry.text}
                              </div>
                              <div>
                                <strong>Submitted by:</strong> {entry.submittedBy}
                              </div>

                              {entry.urls && entry.urls.length > 0 && (
                                <div>
                                  <strong>URLs:</strong>
                                  <ul className="list-disc ml-5">
                                    {entry.urls.map((url, urlIndex) => (
                                      <li key={urlIndex}>
                                        <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                                          {url}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}

                              {entry.files && entry.files.length > 0 && (
                                <div>
                                  <strong>Files:</strong>
                                  <ul className="list-disc ml-5">
                                    {entry.files.map((file, fileIndex) => (
                                      <li key={fileIndex}>
                                        <li key={fileIndex}><a target='_blank' href={`${process.env.REACT_APP_BACKEND_API_URL}/${file.file}`}>{file.name}</a></li>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}

                              <div className="text-white text-sm">
                                <strong>Date:</strong> {new Date(entry.date).toLocaleString()}
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  ))}
              </div>
            ))
          )}
        </TabPanel>

        {/* Access Tab */}
        <TabPanel>
          <h2 className="text-2xl font-bold mb-4">Request Access</h2>
          {accessEntries.length === 0 ? (
            <p>No access data found.</p>
          ) : (
            <ul className="ml-5">
              {accessEntries.map((entry) => (
                <li key={entry.id} className="p-2 bg-kog-300 rounded mb-2 border">
                  <div>
                    <strong>Contact:</strong> {entry.handle || 'N/A'}
                  </div>
                  <div>
                    <strong>Address:</strong> {entry.address || 'N/A'}
                  </div>
                  <div>
                    <strong>Req Ref:</strong> {entry.reqRef || 'N/A'}
                  </div>
                  <div>
                    <strong>Date:</strong> {new Date(entry.date).toLocaleString()}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default AdminDeliverables;
