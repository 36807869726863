import React, { useState } from 'react';

const FileUpload = ({ setFiles }) => {
  const [fileNames, setFileNames] = useState([]); // To display file names
  const fileInputRef = React.useRef(null);

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);

    // Update the UI with file names
    setFileNames((prevNames) => [...prevNames, ...selectedFiles.map(file => file.name)]);
  };

  // Trigger file input when the user clicks on the button
  const handleChooseFilesClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      {/* Display Selected File Names */}
      {fileNames.length > 0 && (
        <div className="mt-4">
          <h4 className="font-bold mb-2">Submitted Files:</h4>
          <ul className="list-disc list-inside">
            {fileNames.map((name, index) => (
              <li key={index} className="text-sm">{name}</li>
            ))}
          </ul>
        </div>
      )}

      {/* Hidden File Input */}
      <input
        type="file"
        multiple
        ref={fileInputRef}
        onChange={handleFileChange}
        className="hidden"
      />

      {/* Custom Button to Trigger File Input */}
      <button
        onClick={handleChooseFilesClick}
        className="bg-gray-200 dark:bg-kog-300 text-kog-100 dark:text-white  p-2 rounded border border-kog-500 shadow-xl mb-2 mt-4 w-full"
      >
        Choose Files
      </button>
    </div>
  );
};

export default FileUpload;
